






import Vue from 'vue'
export default Vue.extend({
  name: 'RuleName',
  props: ['rule']
})
