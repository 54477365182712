<template>
  <Modal
    :title="conversion_action ? 'Edit conversion action' :  'Create new conversion action'"
    :name="'conversion-action-modal'"
    class="modal"
    :show-footer="false"
    :new_footer="true"
  >
    <template #body>
      <div class="modal_item">
        <div class="d-flex align-items-center" style="gap: 16px">
          <div class="img">
            <img src="@/assets/img/globe.svg" alt="globe" width="92px" height="92px"/>
          </div>
          <h2 class="h2-type-2">
            Create a website conversion action to measure<br>
            conversions on your website.
          </h2>
        </div>
        <div class="d-flex justify-content-between name-container-column" style="gap: 16px">
          <div class="form-group ml-3 col-6" style="max-width: 372px">
            <p class="d-flex align-items-center" style="font-weight: 600;font-size: 0.9em;height: 34px;">Goal and action optimization</p>
            <VSelect :append-to-body="true"
                     :popper-custom-classes="['reference-hidden']"
                     :error="!$v.currentCategory.required && $v.currentCategory.$dirty ? 'Category is required': ''"
                     placeholder="Select category"
                     :options="Object.values(categories)"
                     :value="categories[currentCategory]"
                     :v="$v.currentCategory"
                     @input="currentCategory = selectObjectKey($event, categories)"/>
          </div>
          <div
            class="form-group col-6" style="max-width: 372px">
            <BaseInputVuelidate
              title="Conversion name"
              placeholder="Enter name"
              :default="name"
              @changeValue="(name = $event.value) && clearExceptions()"
              :max="100"
              :error-class="!!exceptionMessageBySourceParameter('name')"
              :show-length="false"
              :v="$v.name"
              :min-width="'200px'">
              <span class="error p-2" v-if="!$v.name.required && $v.name.$dirty">{{ $t('error.required', { field: 'Name' }) }}</span>
              <span class="error p-2" v-if="exceptionMessageBySourceParameter('name')">{{ exceptionMessageBySourceParameter('name') }}</span>
            </BaseInputVuelidate>
          </div>
        </div>
        <div class="list_options mt-4">
          <ConversionOptions
            :title="'Value'"
            :label="'Measure the impact of your advertising by giving conversions a value'"
          >
            <template #body>
              <base-radio-button v-model="selectValue"  :opinion-text="valueOptions[0].text" name="valueOptions" :value="valueOptions[0]"/>
              <new-ui-accordion :collapsed="selectValue.type !== valueOptions[0].type" style="padding-left: 28px">
                <div>
                  <p class="p-bold">Enter a default value</p>
                  <div class="d-flex col-6">
                    <input class="form-control input-type-1" placeholder="0" v-model="currency.default_value" type="number" style="max-width: 100px; border-right: none; border-radius: 4px 0 0 4px"/>
                    <v-select :theme="'simple-gray'" :options="currencyCode" v-model="currency.default_currency_code" style="border-radius: 4px 0 0 4px" :popper-custom-classes="['reference-hidden']" :append-to-body="true"/>
                  </div>
                </div>
              </new-ui-accordion>
              <base-radio-button v-model="selectValue" :opinion-text="valueOptions[1].text" name="valueOptions" :value="valueOptions[1]"/>
              <new-ui-accordion :collapsed="selectValue.type !== valueOptions[1].type" style="padding-left: 28px">
                <div>
                  <p class="p-bold">Enter a default value</p>
                  <div class="d-flex col-6">
                    <input class="form-control input-type-1" placeholder="0" v-model="currency.default_value" type="number" style="max-width: 100px; border-right: none; border-radius: 4px 0 0 4px"/>
                    <v-select :theme="'simple-gray'" :options="currencyCode" v-model="currency.default_currency_code" style="border-radius: 4px 0 0 4px" :popper-custom-classes="['reference-hidden']" :append-to-body="true"/>
                  </div>
                </div>
              </new-ui-accordion>
              <base-radio-button v-model="selectValue"  :opinion-text="valueOptions[2].text" name="valueOptions" :value="valueOptions[2]"/>
            </template>
          </ConversionOptions>
          <ConversionOptions
            :title="'Count'"
            :label="'Select how many conversions to count per click or interaction'"
          >
            <template #body>
              <div class="mt-2">
                <TabSwitcher :tab-menu="countSwitch"
                             @switchTab="switchTab($event.activeTab)"
                             :active-tab="activeCountSwitch"
                             :theme="'button-group'"/>
                <div class="mt-2">
                  <p class="p-gray" v-if="activeCountSwitch.name === countSwitch[1].name">Recommended for leads, sign-ups and other conversions because only the first interaction is valuable</p>
                  <p class="p-gray" v-else>Recommended for purchases because every purchase is valuable</p>
                </div>
              </div>
            </template>
          </ConversionOptions>
          <ConversionOptions
            :title="'Conversion window'"
            :label="'Select the maximum time after an ad interaction that you want to count conversions'"
          >
            <template #body>
              <v-select class="mt-2"
                        :options="Object.values(windowOptions)"
                        :value="windowOptions[conversionWindow]"
                        @input="conversionWindow = selectObjectKey($event, windowOptions)"
                        :max-width="372"
                        :popper-custom-classes="['reference-hidden']"
                        :append-to-body="true"/>
            </template>
          </ConversionOptions>
          <ConversionOptions
            :title="'View-through conversion window'"
            :label="'Select the maximum time after a person views you ad, that you want to count view-through conversions'"
          >
            <template #body>
              <v-select class="mt-2"
                        :options="Object.values(viewThroughConversionWindow)"
                        :value="viewThroughConversionWindow[activeViewThroughConversionWindow]"
                        @input="activeViewThroughConversionWindow = selectObjectKey($event, viewThroughConversionWindow)"
                        :max-width="372"
                        :popper-custom-classes="['reference-hidden']"
                        :append-to-body="true"/>
            </template>
          </ConversionOptions>

          <!--        <ConversionListItem-->
          <!--          :title="'Include in “Conversions”'"-->
          <!--        >-->
          <!--          <template #body>-->
          <!--            <div class="p-3">-->
          <!--              <new-ui-checkbox :span="'Include'" @change="include = !include" :checked="include"/>-->
          <!--              <p class="p-gray" style="padding-left: 24px">Include these conversions in your "Conversions" column. If you use Smart Bidding, your bid strategies will optimize for these conversions.</p>-->
          <!--            </div>-->
          <!--          </template>-->
          <!--        </ConversionListItem>-->
          <ConversionOptions
            :title="'Attribution model'"
            :label="'Select an attribution model for your Search Network and Shopping conversions'"
          >
            <template #body>
              <v-select class="mt-2"
                        :options="Object.values(attributionModel)"
                        :max-width="372"
                        :value="attributionModel[activeAttributionModel]"
                        :append-to-body="true"
                        :popper-custom-classes="['reference-hidden']"
                        @input="activeAttributionModel = selectObjectKey($event, attributionModel)"
              />
            </template>
          </ConversionOptions>
        </div>
      </div>
    </template>
    <template #footer_new>
      <div class="modal-footer d-flex align-items-center justify-content-end end-button-container">
        <button type="button" class="btn standard-btn" @click="close_modal">
          Cancel
        </button>
        <button class="btn btn-primary" :disabled="disableBtn" @click="conversion_action ? updateAction() : createAction()">
          Continue
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
import Vue from 'vue'
import BaseInputVuelidate from '@/components/BaseInputVuelidate'
import VSelect from '@/components/BaseComponents/VSelect/VSelect'
import NewUiAccordion from '@/components/NewUIComponents/NewUiAccordion'
import baseRadioButton from '@/components/BaseRadioButton'
import ConversionOptions from '@/components/strategies/Rule/TaskWrapper/ConversionAction/ConverionOptions'
import TabSwitcher from '@/components/TabSwitcher'
import { CONVERSION_WINDOW_OPTIONS, ATTRIBUTION_MODEL, VIEW_THROUGH_CONVERSION_WINDOW, CURRENCY_CODES, CATEGORIES } from '@/constants/GoogleConversionActions'
import { mapActions, mapGetters } from 'vuex'
import Modal from '@/components/DynamicModal.vue'
import { required } from 'vuelidate/lib/validators'
import { messages } from '@/i18n/messages'
import TagSetupConversionAction
  from '@/components/strategies/Rule/TaskWrapper/ConversionAction/TagSetupConversionAction'

export default Vue.extend({
  name: 'ConversionAction',
  components: {
    ConversionOptions,
    BaseInputVuelidate,
    VSelect,
    Modal,
    NewUiAccordion,
    baseRadioButton,
    TabSwitcher
    // NewUiCheckbox
  },
  props: {
    ad_account: {
      type: Object
    },
    conversion_action: {
      type: Object
    }
  },
  validations: {
    name: {
      required
    },
    currentCategory: {
      required
    }
  },
  i18n: {
    messages
  },
  data () {
    return {
      valueOptions: [
        {
          id: '0',
          text: 'Use the same value for each conversion',
          type: 'SAME_VALUE'
        },
        {
          id: '1',
          text: 'Use different value for each conversion',
          type: 'ALWAYS_USE_DEFAULT_VALUE'
        },
        {
          id: '2',
          text: 'Dont use a value for this conversion action (not recommented)',
          type: 'DONT_USE'
        }
      ],
      selectValue: {},
      countSwitch: [
        {
          id: 0,
          name: 'MANY_PER_CLICK',
          title: 'Every',
          description: ''
        }, {
          id: 1,
          name: 'ONE_PER_CLICK',
          title: 'One',
          description: ''
        }
      ],
      activeCountSwitch: {
        id: this.conversion_action?.counting_type === 'MANY_PER_CLICK' ? 0 : 1,
        name: this.conversion_action?.counting_type || 'MANY_PER_CLICK',
        title: this.conversion_action?.counting_type === 'MANY_PER_CLICK' ? 'Every' : 'One',
        description: ''
      },
      currentCategory: this.conversion_action?.category || '',
      currency: {
        default_value: this.conversion_action?.value_settings?.defaultValue || 1,
        default_currency_code: this.conversion_action?.value_settings?.defaultCurrencyCode || this.ad_account.currency,
        always_use_default_value: this.conversion_action?.value_settings?.alwaysUseDefaultValue || false
      },
      name: this.conversion_action?.name || '',
      conversionWindow: this.conversion_action?.click_through_lookback_window_days || 7,
      activeViewThroughConversionWindow: this.conversion_action?.view_through_lookback_window_days || 7,
      activeAttributionModel: this.conversion_action?.attribution_model_settings?.attributionModel || 'GOOGLE_ADS_LAST_CLICK',
      disableBtn: false
    }
  },
  computed: {
    ...mapGetters('exceptions', ['exceptionMessageBySourceParameter']),
    windowOptions () {
      return CONVERSION_WINDOW_OPTIONS
    },
    attributionModel () {
      return ATTRIBUTION_MODEL
    },
    viewThroughConversionWindow () {
      return VIEW_THROUGH_CONVERSION_WINDOW
    },
    categories () {
      return CATEGORIES
    },
    currencyCode () {
      return CURRENCY_CODES
    },
    data () {
      return {
        status: 'ENABLED',
        type: this.conversion_action ? undefined : 'WEBPAGE',
        name: this.name,
        ad_account_id: this.conversion_action ? undefined : +this.ad_account.id,
        category: this.currentCategory,
        value_settings: {
          default_value: this.selectValue.type === 'DONT_USE' ? 0 : this.currency.default_value || 1,
          default_currency_code: this.currency.default_currency_code,
          always_use_default_value: this.selectValue.type === 'SAME_VALUE' || this.selectValue.type === 'DONT_USE' || false
        },
        counting_type: this.activeCountSwitch.name,
        attribution_model_settings: {
          attribution_model: this.activeAttributionModel
        },
        primary_for_goal: false,
        include_in_conversions_metric: true,
        click_through_lookback_window_days: this.conversionWindow,
        view_through_lookback_window_days: this.activeViewThroughConversionWindow
      }
    }
  },
  methods: {
    ...mapActions('googleConversionActions', ['createConversionAction', 'updateConversionAction', 'loadConversionAction']),
    ...mapActions('notifications', { add_exception_to_notification: 'addExceptions' }),
    ...mapActions('exceptions', ['addExceptions', 'clearExceptions']),
    switchTab (event) {
      this.activeCountSwitch = event
    },
    selectObjectKey (value, obj) {
      for (const el in obj) {
        if (obj[el] === value) return el
      }
    },
    createAction () {
      if (!this.$v.$invalid) {
        this.disableBtn = true
        this.createConversionAction(this.data)
          .then((res) => {
            this.loadConversionAction({ ad_account_id: this.ad_account.id })
            this.$modal.hide('conversion-action-modal')
            this.$modal.show(TagSetupConversionAction, {
              conversion_action: res,
              created: true
            }, {
              name: 'tag-setup-conversion-action',
              adaptive: true,
              height: 'auto',
              width: 800
            })
          })
          .catch(er => {
            this.addExceptions(er.response.data)
            this.add_exception_to_notification(er.response.data)
          })
          .finally(() => { this.disableBtn = false })
      } else {
        this.$v.$touch()
      }
    },
    updateAction () {
      if (!this.$v.$invalid) {
        this.disableBtn = true
        this.updateConversionAction({ id: this.conversion_action.id, data: this.data })
          .then(() => {
            this.loadConversionAction({ ad_account_id: this.ad_account.id })
            this.$modal.hide('conversion-action-modal')
            this.$modal.show('conversion-action-list-modal')
          })
          .catch(er => {
            this.addExceptions(er.response.data)
            this.add_exception_to_notification(er.response.data)
          })
          .finally(() => { this.disableBtn = false })
      } else {
        this.$v.$touch()
      }
    },
    close_modal () {
      this.$modal.hide('conversion-action-modal')
    }
  },
  mounted () {
    if (this.conversion_action) {
      if (this.conversion_action?.value_settings?.defaultValue && this.conversion_action?.value_settings?.alwaysUseDefaultValue) {
        this.selectValue = this.valueOptions[0]
      } else if (this.conversion_action?.value_settings?.defaultValue && !this.conversion_action?.value_settings?.alwaysUseDefaultValue) {
        this.selectValue = this.valueOptions[1]
      } else {
        this.selectValue = this.valueOptions[2]
      }
    } else {
      this.selectValue = this.valueOptions[0]
    }
  }
})
</script>
<style lang="scss">
.modal_item {
  max-height:  calc(80vh - 60px - 80px - 78px - 32px);
  .list_options {
    .list_item {
      border-top: 1px solid #DEE2E6;
      .btn_list {
        max-width: 43px;
        max-height: 36px;
      }
    }
  }
}

</style>
