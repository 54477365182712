<template>
  <div class="mb-2">
<!--    <button class="trigger-group d-flex justify-content-start align-items-star pl-0 bold">-->
<!--      <div-->
<!--        class="dropdown-result-icon mr-2"-->
<!--        :class="{ expanded: !isDropdownExpanded }"-->
<!--      >-->
<!--        <img src="../../../../../assets/icons/chevron-icon.svg" alt="" @click.stop="expandDropdown">-->
<!--      </div>-->
      <div class="d-flex w100 justify-content-between px-2">
<!--        <span class="ps-1" @click.stop="expandDropdown">Conversion actions</span>-->
        <button class="btn btn-custom btn-sm w-100" style="font-size: 12px" @click="listModal">
          Conversion actions list
        </button>
      </div>
<!--    </button>-->
<!--    <new-ui-accordion :collapsed="isDropdownExpanded">-->
<!--      <div-->
<!--        class="mb-2">-->
<!--        <div-->
<!--          v-for="(item) in getConversionAction"-->
<!--          :key="item.id"-->
<!--          class="select-item d-flex justify-content-between ps-4 pe-2"-->
<!--        >-->
<!--          <button-->
<!--            class="item"-->
<!--          >-->
<!--            <span style="font-size: 0.9em">{{ item.name }}</span>-->
<!--          </button>-->
<!--          <div class="d-flex" style="gap: 10px">-->
<!--            <button class="img">-->
<!--              <img :src="require('@/assets/icons/info-icon-gray.svg')" alt="">-->
<!--            </button>-->
<!--            <button class="img">-->
<!--              <img :src="require('@/assets/icons/edit-icon.svg')" alt="" @click="editModal(item.id)">-->
<!--            </button>-->
<!--            <button class="img">-->
<!--              <img :src="require('@/assets/icons/trash-icon.svg')" alt="" @click="deleteConversion(item.id)">-->
<!--            </button>-->
<!--          </div>-->

<!--        </div>-->
<!--      </div>-->
<!--    </new-ui-accordion>-->
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import NewUiAccordion from '@/components/NewUIComponents/NewUiAccordion'
import ConversionAction from '@/components/strategies/Rule/TaskWrapper/ConversionAction/ConversionAction'

export default Vue.extend({
  name: 'ConversionDropdownOptions',
  props: {
    ad_account: {
      type: Object
    }
  },
  // components: { NewUiAccordion },
  data () {
    return {
      isDropdownExpanded: true
    }
  },
  computed: {
    ...mapGetters('googleConversionActions', ['getConversionAction', 'getById'])
  },
  methods: {
    ...mapActions('googleConversionActions', ['deleteConversionAction', 'loadConversionAction']),
    expandDropdown () {
      this.isDropdownExpanded = !this.isDropdownExpanded
    },
    listModal () {
      this.$modal.show('conversion-action-list-modal', {
        ad_account: this.ad_account
      })
    },
    editModal (id) {
      this.$modal.show(ConversionAction, {
        ad_account: this.ad_account,
        conversion_action: this.getById(id)
      }, {
        name: 'conversion-action-modal',
        adaptive: true,
        height: 'auto',
        overlayTransition: 'linear',
        classes: 'CreateCustomMetricModal',
        width: 800
      })
    },
    deleteConversion (id) {
      this.deleteConversionAction(id).then(() => {
        this.loadConversionAction({ ad_account_id: this.ad_account.id })
      })
    }
  }
})
</script>

<style scoped lang="scss">
.trigger-group {
  margin-bottom: 12px;
  width: 100%;
span {
  text-align: left;
  font-weight: 600;
  font-size: 0.9em
}
}
.select-item {
  margin-bottom: 10px;
}
.chosen {
  position: relative;
}
.chosen::before {
  position: absolute;
  content: "";
  top: 25%;
  left: 0;
  background-repeat: no-repeat;
  width: 12px;
  height: 12px;
}
</style>
