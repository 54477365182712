<template>
  <Modal
    :title='`Tag setup for "${conversion_action.name}" website conversion action`'
    :name="'tag-setup-conversion-action'"
    class="modal"
    :show-footer="false"
  >
    <template #body>
      <div class="mb-4" v-if="created">
        <div class="alert alert-type-1 alert-success alert-dismissible fade show" role="alert">
          <div class="alert-icon">
            <i class="fak fa-circle-check-solid"></i>
          </div>
          <div class="alert-text">
            <strong>Success</strong> <br>
            Your conversion action was succesfully created
          </div>
        </div>
      </div>
      <div class="d-flex flex-column pb-4" style="gap: 16px; border-bottom: 1px solid #DEE2E6">
        <div class="d-flex flex-column dropdown-selected" style="gap: 16px">
          <div>
            <p class="p-bold">Select the framework that your website code uses</p>
            <p class="p-gray">To add the tag and snippet, select the framework that your page uses and follow the instructions</p>
          </div>
          <VSelect :options="['HTML', 'AMP']"
                   v-model="type_snippet"
                   :max-width="80"/>
        </div>
        <div class="d-flex flex-column" style="gap: 8px">
          <h3 class="h2-type-2">Global site tag</h3>
          <p class="p-gray">The global site tag adds visitors to your  basic remarketing lists and sets new cookies on your domain, which will store information about the ad click that brought a user to your website. You must install this tag on every page of your website</p>
        </div>
        <div class="d-flex flex-column">
          <p class="p-gray">Copy the tag below and paste it in between the &lt;head&gt;&lt;/head&gt; tags of every page of your website. You only need to install the Google tag once per account, even if you are tracking multiple actions.</p>
          <textarea class="form-control input-type-1 mt-4" disabled :value="valueGlobal" id="exampleFormControlTextarea2" rows="10"></textarea>
        </div>
        <div>
          <v-popover
            offset="6"
            :placement="'top'"
            :popover-class="'popover-dark'"
          >
            <button class="btn btn-light" @click="copySnippet(valueGlobal)">Copy snippet</button>
            <template slot="popover">
              <a v-close-popover="true">Snippet copied</a>
            </template>
          </v-popover>
        </div>
      </div>
      <div class="d-flex flex-column mt-4 pb-4" style="gap: 16px">
        <div class="d-flex flex-column" style="gap: 8px">
          <h3 class="h2-type-2">Event snippet</h3>
          <p class="p-gray">The event snippet works with the Google tag to track actions that should be counted as conversions. Choose whether to track conversions on a page load or click.</p>
        </div>
        <div>
          <p class="p-bold">Event type</p>
          <p class="p-gray">Choose whether to track conversions on a page load or click</p>
          <base-radio-button name="event_snippet"
                             v-model="type_event"
                             value="load"
                             opinionText="Page load"/>
          <p class="p-gray" style="padding-left: 28px">Use this option if you want to add the snippet to the page a customer reaches after completing a conversion</p>
          <base-radio-button name="event_snippet"
                             v-model="type_event"
                             value="click"
                             opinion-text="Click"/>
          <p class="p-gray" style="padding-left: 28px">Add the snippet to the page that has a button or a link you'd like to track for clicks</p>
        </div>
        <div class="d-flex flex-column">
          <p class="p-gray">Copy the snippet below and paste it in between the &lt;head&gt;&lt;/head&gt; tags of the page(s) that you'd like to track, right after the Google tag</p>
          <textarea class="form-control input-type-1 mt-4" disabled :value="valueEvent" id="exampleFormControlTextarea2" rows="10"></textarea>
        </div>
        <div>
          <v-popover
            offset="6"
            :placement="'top'"
            :popover-class="'popover-dark'"
          >
            <button class="btn btn-light"
                    @click="copySnippet(valueEvent)"
            >Copy snippet</button>
            <template slot="popover">
              <a v-close-popover="true">Snippet copied</a>
            </template>
          </v-popover>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Vue from 'vue'
import Modal from '@/components/DynamicModal.vue'
import VSelect from '@/components/BaseComponents/VSelect/VSelect'
import BaseRadioButton from '@/components/BaseRadioButton'
import { mapActions } from 'vuex'
export default Vue.extend({
  name: 'TagSetupConversionAction',
  props: {
    conversion_action: {
      type: Object
    },
    created: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      type_snippet: 'HTML',
      type_event: 'load'
    }
  },
  components: { Modal, VSelect, BaseRadioButton },
  computed: {
    valueGlobal () {
      if (this.type_snippet === 'HTML') {
        return this.conversion_action.tag_snippets.find(el => el.pageFormat === 'HTML').globalSiteTag
      } else { return this.conversion_action.tag_snippets.find(el => el.pageFormat === 'AMP').globalSiteTag }
    },
    valueEvent () {
      if (this.type_event === 'load') {
        if (this.type_snippet === 'HTML') {
          return this.conversion_action.tag_snippets.find(el => {
            if (el.type === 'WEBPAGE' && el.pageFormat === 'HTML') { return el }
          }).eventSnippet
        } else {
          return this.conversion_action.tag_snippets.find(el => {
            if (el.type === 'WEBPAGE' && el.pageFormat === 'AMP') { return el }
          }).eventSnippet
        }
      } else {
        if (this.type_snippet === 'HTML') {
          return this.conversion_action.tag_snippets.find(el => {
            if (el.type === 'WEBPAGE_ONCLICK' && el.pageFormat === 'HTML') { return el }
          }).eventSnippet
        } else {
          return this.conversion_action.tag_snippets.find(el => {
            if (el.type === 'WEBPAGE_ONCLICK' && el.pageFormat === 'AMP') { return el }
          }).eventSnippet
        }
      }
    }
  },
  methods: {
    ...mapActions('notifications', ['addNotification']),
    copySnippet (value) {
      try {
        navigator.clipboard.writeText(value)
      } catch (e) {
        this.addNotification({ type: 'danger', body: 'Oops, copy failed. Please try again later' })
      }
    }
  }
})
</script>

<style scoped lang="scss">
.dropdown-selected {
  ::v-deep .vs__selected {
    margin: 6px 2px 0;
  }
}

</style>
