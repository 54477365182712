<template>
  <div class="custom-modal-content bg-gray-light">
    <div class="d-flex custom-modal-header border-bottom py-4">
      <h3 class="bold">{{ actionTitle }} custom metrics</h3>
      <button
        type="button"
        class="ms-auto btn_svg d-flex align-items-center"
        @click="close_modal"
        aria-label="Close"
      >
        <img src="../../../../assets/icons/close-icon.svg" alt="Close modal"/>
      </button>
    </div>

    <div class="modal-body">
      <div class="d-flex justify-content-between name-container-column">
        <div
          class="form-group col-7"
          :class="{ 'has-error': errors['custom_metric.name'] }"
        >
          <BaseInputVuelidate
            title="Name *"
            :default="custom ? custom.name : ''"
            placeholder="Custom metric name"
            :v="$v.name"
            :min-width="'200px'"
            @changeValue="name = $event.value">
            <span class="error p-2" v-if="!$v.name.required && $v.name.$dirty">{{ $t('error.empty') }}</span>
            <span class="error p-2" v-if="getError('custom_metric.name')">{{ getError('custom_metric.name') }}</span>
          </BaseInputVuelidate>
        </div>

        <div class="form-group col-4 ml-3">
          <p class="d-flex align-items-center" style="font-weight: 600;font-size: 0.9em;height: 34px;">Format</p>
          <VSelect @input="setFormat"
                   :value="format"
                   :options="['NUMERIC', 'MONEY', 'PERCENTAGE']"
          />
        </div>
      </div>

      <div class="form-group border-bottom mt-3">
        <BaseTextArea @changeValue="description = $event.value"
                      :default="custom ? custom.description : ''"
                      :title="'Description'"
                      :placeholder="'Description metric'"/>
      </div>
      <!-- metric dropdown -->
      <div class="pt-4">
        <p class="d-flex align-items-center pb-3" style="font-weight: 600;font-size: 0.9em;height: 34px;">Please indicate an operator or add metric</p>
        <div class="d-flex operators-desk flex-column">
          <div class="d-flex justify-content-between operators-desk-metrics">
            <div class="position-relative w-25">
              <button
                class="form-control standard-btn d-flex align-items-center me-2"
                style="width: fit-content; max-width: 200px;"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis"> {{ metric_title }} </span>
                <svg
                  style="margin-right: -7px"
                  class="ms-2"
                  width="11"
                  height="7"
                  viewBox="0 0 9 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.6 5.4L0.2 2C-0.1 1.8-0.1 1.4 0.2 1.2L0.7 0.6C1 0.4 1.4 0.4 1.6 0.6L4 3 6.4 0.6C6.6
              0.4 7 0.4 7.3 0.6L7.8 1.2C8.1 1.4 8.1 1.8 7.8 2L4.4 5.4C4.2 5.6 3.8 5.6 3.6 5.4Z"
                    fill="#555"
                  />
                </svg>
              </button>

              <div class="dropdown-menu lg pl-3 pr-2">
                <div class="dropdown-select-content">
                  <label class="search-metrics-input w-100 mb-3 d-flex align-items-center">
                    <img v-if="search_trigger.length > 0"
                         @click.stop="search_trigger = ''"
                         src="/d/img/fb_automated_rules/icn-close.svg" alt="">
                    <input class="search-item-modal"
                           type="text"
                           placeholder="Search trigger"
                           v-model="search_trigger"
                    />
                  </label>
                  <template v-for="(group, index) in filteredTriggers">
                    <template v-if="group.group !== 'Custom metrics'">
                      <div
                        class="bold d-flex align-items-center justify-content-between"
                        style="margin-bottom: 12px; cursor: pointer"
                        @click.stop="toggleGroup(index)"
                        :key="index"
                      >
                        <img style="width: 13px" src="../../../../assets/icons/chevron-icon.svg" alt="">
                        <span style="width: 100%;text-align: left; font-size: 0.9em; margin-left: 10px">
                        {{ group.group }}
                      </span>
                      </div>

                      <div
                        class="mb-2"
                        :class="{ hidden: !group.opened, show: (filteredMetrics(group).length > 0 && search_trigger.length > 0) }"
                        style="margin-bottom: 12px"
                        :key="index + 'group'"
                      >
                        <div
                          v-for="metric in filteredMetrics(group)"
                          :key="metric.field"
                          class="d-flex justify-content-between"
                          style="margin-bottom: 10px"
                          @click="setMetric(metric)"
                        >
                      <span
                        style="margin-left: 24px; font-size: 0.9em; cursor: pointer"
                        :class="{
                          chosen: selectedMetrics.includes(metric.field)
                        }"
                      >
                        {{ metric.name }}
                      </span>
                          <!-- tooltip -->
                          <img
                            src="../../../../assets/icons/info-icon-gray.svg"
                            style="width: 12px; cursor: pointer"
                            v-tooltip.bottom="metric.description"
                            class="custom-tooltip"
                          />
                        </div>
                      </div>
                    </template>
                  </template>
                </div>
              </div>
            </div>
            <time-range-section
              :demo-rule="false"
              style="width: 100%;max-width: 200px; border: none"
              :time_range="time_range"
              @set_time_range="setTimeRange"
              :theme="'custom_metric'"
            ></time-range-section>
            <button class="standard-btn standard-btn-primary" @click="addMetric">Add</button>
          </div>
          <div class="d-flex number-container">
            <!-- number -->
            <div class="position-relative w-25 ml-2 mr-3 me-2">
              <button
                class="form-control standard-btn  me-2 number-size"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span> Number </span>
                <svg
                  style="margin-right: -7px"
                  class="ms-2"
                  width="11"
                  height="7"
                  viewBox="0 0 9 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.6 5.4L0.2 2C-0.1 1.8-0.1 1.4 0.2 1.2L0.7 0.6C1 0.4 1.4 0.4 1.6 0.6L4 3 6.4 0.6C6.6
              0.4 7 0.4 7.3 0.6L7.8 1.2C8.1 1.4 8.1 1.8 7.8 2L4.4 5.4C4.2 5.6 3.8 5.6 3.6 5.4Z"
                    fill="#555"
                  />
                </svg>
              </button>

              <div class="dropdown-menu sm pl-3 pr-2">
                <div class="dropdown-select-content d-flex align-items-center">
                  <input
                    class="input form-control mr-2"
                    style="max-height: 34px"
                    type="number"
                    v-model="number"
                    @keydown="enterNumber"
                    @keyup.enter="$refs.addNumberButton.click()"
                  />
                  <button
                    ref="addNumberButton"
                    class="standard-btn ms-2"
                    @click="addNumber"
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
            <!-- operation buttons -->
            <!-- addition -->
            <div class="button-row">
              <button class="btn btn_svg square standard-btn standard-btn-single-icon me-2 operator-button" @click="addOperator('addition')">
                <svg viewBox="0 0 14 15" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13 5.5V8.5H8V13.5H5V8.5H0V5.5H5V0.5H8V5.5H13Z"/>
                </svg>
              </button>
              <!-- subtraction -->
              <button class="btn btn_svg square standard-btn standard-btn-single-icon me-2 operator-button" @click="addOperator('subtraction')">
                <svg viewBox="0 0 14 4" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13 0.5V3.5H8H5H0V0.5H5H8H13Z"/>
                </svg>
              </button>
              <!-- division -->
              <button class="btn btn_svg square standard-btn standard-btn-single-icon me-2 operator-button" @click="addOperator('division')">
                <svg viewBox="0 0 14 15" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12 0.9L14.1 3.1 9.2 8 7.1 10.1 2.1 15.1 0 12.9 4.9 8 7.1 5.9 12 0.9Z"
                  />
                </svg>
              </button>
              <!-- multiplication -->
              <button
                class="btn btn_svg square standard-btn standard-btn-single-icon operator-button"
                @click="addOperator('multiplication')"
              >
                <svg viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M9.7 0.3L11.8 2.5 8.3 6 11.8 9.5 9.7 11.7 6.2 8.1 2.7 11.7 0.5
              9.5 4.1 6 0.5 2.5 2.7 0.3 6.2 3.9 9.7 0.3Z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="errors d-flex align-items-center pt-3" v-if="getError('custom_metric.metric')">
        <div class="error-icon">
          <img style="max-width: 12px; margin: 0 5px 0 0" src="../../../../assets/icons/info-icon.svg" alt="">
        </div>
        <span class="ps-2" style="color: #dc3545">{{getError('custom_metric.metric')}}</span>
      </div>
      <!-- display section -->
      <div class="form-control display-section p-1">
        <span v-if="!customSet.length" class="text-muted m-2" style="color: #989898;font-size: 0.9em">
          Your custom metric
        </span>
        <button
          class="btn_svg"
          v-for="(item, index) in customSet"
          @click="deleteItem(index)"
          :key="index"
        >
          <span class="display-section-item-name">{{item.name || item.value}}</span>
          <span class="display-section-item-time-range">{{ display_time_range(item) }}</span>
        </button>
      </div>
    </div>

    <div class="modal-footer d-flex align-items-center justify-content-end end-button-container">
      <button type="button" class="standard-btn" @click="close_modal">
        Cancel
      </button>
      <button
        id="createMetricButton"
        type="button"
        class="standard-btn standard-btn-primary ms-2"
        @click="done"
        :disabled="isMetricInvalid"
      >
        {{ actionTitle }} metric
      </button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import _ from 'lodash'
import BaseTextArea from '@/components/BaseComponents/BaseTextArea/BaseTextArea.vue'
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import VSelect from '@/components/BaseComponents/VSelect/VSelect.vue'
import TimeRangeSection from '@/components/strategies/Rule/TaskWrapper/TimeRangeSection'
import BaseInputVuelidate from '@/components/BaseInputVuelidate'
import { messages } from '@/i18n/messages'
import { required } from 'vuelidate/lib/validators'

export default Vue.extend({
  name: 'CustomMetric',
  props: {
    rule: {
      required: true,
      type: Object
    },
    ruleId: {
      required: true
    },
    condition: {
      required: true,
      type: Object
    },
    custom: {
      required: false
    }
  },
  data () {
    return {
      name: this.custom ? this.custom.name : null,
      description: this.custom ? this.custom.description : '',
      format: this.custom ? this.custom.format : 'NUMERIC',
      number: 0,
      search_trigger: '',
      selected_metric: null,
      time_range: null,
      customSet: this.custom
        ? JSON.parse(JSON.stringify(this.custom.metric))
        : [],
      operators: {
        addition: '+',
        subtraction: '-',
        division: '/',
        multiplication: 'x'
      }
    }
  },
  validations: {
    name: {
      required
    }
  },
  i18n: {
    messages
  },
  components: {
    BaseTextArea, VSelect, BaseInputVuelidate, TimeRangeSection
  },
  watch: {
    name (value) {
      this.removeError('custom_metric.name')
    }
  },
  computed: {
    ...mapState({
      triggers: state =>
        state.rulesMeta.triggers.map(group => ({
          opened: false,
          ...group
        })),
      errors: state => state.rules.errors
    }),
    ...mapGetters('rules', ['getError']),
    ...mapGetters('rulesMeta', ['getTimeRange']),
    filteredTriggers () {
      if (this.search_trigger) {
        return _.filter(this.triggers, group => {
          return (
            _.filter(group.fields,
              trigger =>
                trigger.name
                  .toLowerCase()
                  .indexOf(this.search_trigger.toLowerCase()) !== -1).length > 0)
        })
      } else {
        return this.triggers
      }
    },
    selectedMetrics () {
      return this.customSet.map(item => item.field)
    },

    isMetricInvalid () {
      return this.customSet.length < 3 ||
        this.customSet[this.customSet.length - 1].type === 'operator'
    },

    actionTitle () {
      return this.custom ? 'Update' : 'Create'
    },
    metric_title () {
      return this.selected_metric?.name ?? 'Metric'
    }
  },

  beforeDestroy () {
    if (Object.keys(this.errors).length) this.setErrors([])
  },

  methods: {
    ...mapMutations('rules', [
      'setConditionProp',
      'removeError',
      'setErrors'
    ]),

    ...mapActions('rules', [
      'saveCustomMetric',
      'updateCustomMetric',
      'addError'
    ]),

    filteredMetrics (group) {
      return _.filter(
        group.fields,
        metric => metric.type !== 'custom' && metric.custom_metrics !== false && metric.name.toLowerCase().indexOf(this.search_trigger.toLowerCase()) !== -1
      )
    },
    toggleGroup (index) {
      const newState = !this.triggers[index].opened
      Vue.set(this.triggers[index], 'opened', newState)
      this.$forceUpdate()
      // enable tooltips on open
      // if (newState === true) $('[data-toggle="tooltip"]').tooltip()
    },

    enterNumber (ev) {
      if (ev.keyCode === 69) ev.preventDefault()
    },
    setMetric (metric) {
      this.selected_metric = metric
    },
    setTimeRange (time_range) {
      this.time_range = time_range
    },
    setFormat (event) {
      this.format = event
    },
    addMetric () {
      // check if metric formula is valid (no items or last one is operator)
      if (!this.selected_metric) {
        this.addError({
          source: {
            parameter: 'metric'
          },
          detail: ['Please select metric']
        })
        return
      }
      if (
        !this.customSet.length ||
        this.customSet[this.customSet.length - 1].type === 'operator'
      ) {
        this.customSet.push({
          ...this.selected_metric,
          type: 'metric',
          time_range: this.time_range
        })
        this.removeError('metric')
        this.selected_metric = null
        this.time_range = null
      } else {
        this.addError({
          source: {
            parameter: 'metric'
          },
          detail: ['Operator is required']
        })
      }
    },

    addNumber () {
      if (!this.number) return
      // check if metric formula is valid (no items or last one is operator)
      if (
        !this.customSet.length ||
        this.customSet[this.customSet.length - 1].type === 'operator'
      ) {
        this.customSet.push({
          type: 'number',
          value: this.number
        })
        this.removeError('metric')
      } else {
        this.addError({
          source: {
            parameter: 'metric'
          },
          detail: ['Operator is required']
        })
      }
    },

    addOperator (field) {
      // check if metric formula has metric as last item
      if (
        this.customSet.length &&
        this.customSet[this.customSet.length - 1].type !== 'operator'
      ) {
        this.customSet.push({
          type: 'operator',
          field,
          name: this.operators[field]
        })
        this.$forceUpdate()
        this.removeError('metric')
      } else {
        this.addError({
          source: {
            parameter: 'metric'
          },
          detail: ['Metric or number is required']
        })
      }
    },

    deleteItem (index) {
      if (index === this.customSet.length - 1) this.customSet.splice(index, 1)
    },

    close_modal () {
      this.$attrs.on_close('custom-metric')
    },

    save () {
      this.saveCustomMetric({
        data: {
          name: this.name,
          format: this.format.toUpperCase(),
          description: this.description,
          metric: this.customSet
        },
        ruleId: this.ruleId,
        itemId: this.condition.id
      }).then(res => {
        if (res.status !== 201) {
          return null
        } else {
          this.$attrs.on_close('custom-metric')
        }
      })
    },

    update () {
      this.updateCustomMetric({
        data: {
          name: this.name,
          format: this.format.toUpperCase(),
          description: this.description,
          metric: this.customSet
        },
        metricId: this.custom.id
      }).then(res => {
        if (!res) {
          return null
        } else {
          this.$attrs.on_close('custom-metric')
        }
      })
    },

    done () {
      this.custom ? this.update() : this.save()
    },
    display_time_range (item) {
      let name = ''
      if (item.time_range && item.time_range !== true) {
        name = ': ' + this.getTimeRange(item.time_range)?.name
      }
      return name
    }
  }
})
</script>

<style lang="scss" scoped>
.bold {
  font-weight: 400;
}
.show {
  display: block!important;
}
button.form-control {
  background-color: white;
}
.hidden {
  display: none;
}
.custom-modal-content {
  .custom-modal-header {
    padding: 10px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #EAEAEA;
  }
  .modal-body {
    padding: 20px 30px;
    .operators-desk {
      padding: 10px;
      background: #F3F3F3;
      display: flex;
      align-items: flex-start;
      &-metrics {
        padding-bottom: 15px;
        width: 100%;
      }
    }
  }
  .modal-footer {
    padding: 20px 30px;
    border-top: 1px solid #EAEAEA;
  }
}
.display-section {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  min-height: 140px;
  margin: 1.5rem 0 1rem;
  background-color: transparent;
  border: 1px solid #D2D6DE;

  > button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    min-width: 34px;
    min-height: 34px;
    background-color: #d2d6de;
    margin: 0px 3px;
    padding: 0px 10px;
    border-radius: 3px;
    cursor: pointer;
  }
  &-item-name {
    font-style: normal;
    font-weight: 600;
  }
  &-item-time-range {
    font-style: normal;
    font-weight: 400;
  }
}
.dropdown-menu {
  // padding: 10px;
  max-height: 400px;
  overflow-y: scroll;
  padding: 20px;
}
.dropdown-menu.sm {
  min-width: 200px;

  .dropdown-select-content {
    margin: 0;
    overflow: hidden;
  }

  input {
    max-width: 120px;
  }
}

.btn.square {
  justify-content: center;

  svg {
    fill: #999;
    width: 15px;
    height: 15px;
  }
}
@media (max-width: 500px) {
  .operators-desk-metrics {
    flex-direction: column;
    row-gap: 10px;
    padding-bottom: 10px !important;
    & > * {
      padding: 0 !important;
      width: 100% !important;
      height: 32px;
      max-width: unset !important;
      & > button {
        width: 100%;
        margin-bottom: 0 !important;
      }
    }
  }
  .name-container-column {
     flex-direction: column;
    & > * {
      padding: 0 !important;
      width: 100%;
    }
   }
  .end-button-container{
    justify-content: space-between !important;
    & > * {
      padding: 0 !important;
      width: 100%;
    }
  }
  .number-container {
    flex-direction: column;
    row-gap: 10px;
    width: 100%;
    align-items: unset;
  }
  .number-size {
    margin: 0 !important;
    width: 100%;
    padding: 0 !important;
  }
}

.button-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  & > * {
    width: 100%;
  }
  .operator-button {
    min-height: 34px !important;
  }
}
</style>
