


























































import Vue from 'vue'

import TimeGreaterLessThan from '@/components/strategies/Rule/TaskWrapper/ConditionItemComplex/TimeGreaterLessThan.vue'
import TriggerSection from '@/components/strategies/Rule/TaskWrapper/TriggerSection.vue'
import {
  METRIC_OPERATORS,
  METRIC_TYPE_COMPLEX, OPERATORS_TITLE
} from '@/constants/FbAutomatedRule'
import moment from 'moment'
import { mapMutations } from 'vuex'
interface Methods {
  saveConditionProp: (type: string, value: any) => void
  deleteCondition: () => void
  selectMetric: (metric: any) => void
}
interface Props {
  rule: Record<any, any>
  ruleId: any
  condition: Record<any, any>
  conditionGroup: Record<any, any>
  groupId: any
  level: any
  demoRule: boolean
}
interface Data {
  itemId: any,
  components: any,
  operators: any
}

interface Computed {
  metric_component: string
  selectedMetric: any
  valueType: string
  selectedMetricKey: any
  suitableOperators: Record<string, any>
}
export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'ConditionItemComplex',
  props: {
    rule: {
      required: true,
      type: Object
    },
    ruleId: {
      required: true
    },
    condition: {
      required: true,
      type: Object
    },
    conditionGroup: {
      required: true,
      type: Object
    },
    groupId: {
      required: true
    },
    level: {
      required: true,
      type: Number
    },
    demoRule: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  components: { TimeGreaterLessThan, TriggerSection },
  data () {
    return {
      itemId: this.condition.uid,
      components: {
        time_greater_than: 'TimeGreaterLessThan',
        time_less_than: 'TimeGreaterLessThan'
      },
      operators: OPERATORS_TITLE
    }
  },
  computed: {
    metric_component (): string {
      return this.components[this.condition.metric]
    },
    selectedMetric (): any {
      return this.$store.getters['rulesMeta/selectedTrigger'](
        this.selectedMetricKey
      )
    },
    valueType (): string {
      return this.selectedMetric && this.selectedMetric.type
        ? this.selectedMetric.type
        : 'numeric'
    },
    selectedMetricKey: {
      get (): string {
        return this.condition.metric
      },
      set (v): void {
        this.saveConditionProp('metric', v)
        this.saveConditionProp('metric_type', METRIC_TYPE_COMPLEX)
        // if (this.hasErrors) this.setErrors({})
      }
    },
    suitableOperators (): Record<string, any> {
      if (this.selectedMetric && this.selectedMetric.operators) {
        return this.selectedMetric.operators
      } else if (!this.valueType || this.valueType === 'double') {
        return this.operators.numeric
      } else {
        return this.operators[this.valueType]
      }
    }
  },

  methods: {
    ...mapMutations('rules', ['removeErr']),
    deleteCondition (): void {
      this.removeErr({ key: this.condition.id, field: '' })
      this.$store.commit('rules/deleteCondition', {
        ruleId: this.$props.ruleId,
        groupId: this.$props.groupId,
        itemId: this.$data.itemId,
        level: this.$props.level
      })
      if (!Object.keys(this.$props.rule.conditions.conditions).length) {
        this.$store.commit('rules/addDefaultGroup', {
          ruleId: this.$props.ruleId,
          level: 0
        })
      }
    },
    saveConditionProp (type: string, value: any): void {
      this.$store.dispatch('rules/setConditionProp', {
        ruleId: this.$props.ruleId,
        itemId: this.$data.itemId,
        type: type,
        value: value
      })
    },
    selectMetric (metric: any): void {
      this.removeErr({ key: this.condition.id, field: '' })
      if (!metric.is_complex) {
        // TODO: временный костыль до рефакторинга conditions
        let res = null
        if (metric.type === 'datetime') {
          res = moment().unix()
        } else if (metric.type === 'dropdown') {
          res = []
        } else if (metric.type === 'string') {
          res = null
        } else {
          res = 0
        }
        this.saveConditionProp('value', res)
        // update operator if previous operator not available
        this.saveConditionProp('operator', Object.keys(this.suitableOperators)[0])
      } else {
        this.saveConditionProp('metric_type', METRIC_TYPE_COMPLEX)
        if (METRIC_OPERATORS[metric.field]) {
          this.saveConditionProp('operator', METRIC_OPERATORS[metric.field])
        }
      }
    }
  }
})
