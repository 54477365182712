<template>
  <div class="wtc-table-wrapper mt-3">
    <table class="wtc-table">
      <thead>
        <tr class="py-2">
          <th></th>
          <th></th>
          <th>Su</th>
          <th>Mo</th>
          <th>Tu</th>
          <th>We</th>
          <th>Th</th>
          <th>Fr</th>
          <th>Sa</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in intervals"
          :class="tr_class(item)"
          :style="td_style(item)"
          :key="index"
        >
          <!-- sub rows toggle cell -->
          <td :class="td_class(item)" @click="toggle_td(item)">
            <img
              v-if="item.type === 'row'"
              src="../../../../../assets/chevrone-icon.svg"
              :style="{ transform: 'rotate(' + arrow_rotate(item) + 'deg)' }"
              alt="Toggle extra time"
            />
            <span>{{ item.value }}</span>
          </td>
          <!-- week toggle cell -->
          <td
            :class="{ clicked: isWeekClicked(item) }"
            @click="setWeek(item)"
          >
            <input type="checkbox" :checked="isWeekClicked(item)">
          </td>
          <!-- day-time toggle cell -->
          <scheduler-cell
            v-for="day in weekdays"
            :key="item.time + '_' + day"
            :value="day"
            :clicked="isCellClicked(item, day)"
            @cell_click="toggleCell($event, item)"
          />
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Vue from 'vue'
import SchedulerCell from './SchedulerCell.vue'
import _ from 'lodash'
import moment from 'moment'
export default Vue.extend({
  name: 'Scheduler',
  props: ['time_intervals', 'sub_time', 'value', 'demoRule'],
  components: { SchedulerCell },
  data () {
    return {
      data_intervals: null,
      weekdays: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      clickedCells: []
    }
  },

  watch: {
    clickedCells: function (newVal) {
      this.$emit('input', newVal)
    }
  },

  computed: {
    intervals () {
      let id = -1
      return _.flatten(
        _.map(
          this.data_intervals,
          function (i) {
            id += 1
            return [
              {
                value: i.value,
                type: 'row',
                id: id,
                opened: i.opened,
                time: moment(i.value, 'hh a').format('HH:mm')
              }
            ].concat(
              _.map(this.sub_time, function (st) {
                return {
                  value: i.value.split(' ')[0] + ':' + st,
                  type: 'sub',
                  parent: id,
                  time: moment(i.value, 'hh a').minute(st).format('HH:mm')
                }
              })
            )
          }.bind(this)
        )
      )
    }
  },
  mounted () {
    const values_hours = this.value ? this.value.map(v => moment(v.time, 'HH:mm')) : []
    this.data_intervals = this.time_intervals.map(int => {
      const tmp = values_hours.find(v => v.minutes() !== 0 && v.hours() === moment(int, 'hh a').hours())
      return {
        value: int,
        opened: !!tmp
      }
    })
    if (this.value) this.clickedCells = this.value
  },
  methods: {
    tr_class (i) {
      return i.type === 'row' ? 'wtc-row' : 'wtc-row sub'
    },
    td_class (i) {
      return {
        'wtc-side-time': i.type === 'row',
        'sub-wtc-side-time': i.type !== 'row',
        opened: i.opened
      }
    },
    td_style (item) {
      if (item.type === 'row') return null
      else {
        const parent = _.find(this.intervals, function (i) {
          return i.id === item.parent
        })
        return { display: parent.opened ? 'table-row' : 'none' }
      }
    },
    toggle_td (item) {
      if (item.type !== 'row') return null
      const interval = _.find(this.data_intervals, function (t) {
        return t.value === item.value
      })
      interval.opened = !interval.opened
    },
    arrow_rotate (item) {
      return item.opened ? '180' : '360'
    },
    isCellClicked (item, d) {
      const cell = _.find(this.clickedCells, function (cl) {
        return cl.time === item.time && cl.day === d
      })
      return !!cell
    },
    isWeekClicked (item) {
      return (
        _.filter(this.clickedCells, function (cl) {
          return cl.time === item.time
        }).length === 7
      )
    },

    toggleCell (d, item) {
      const cell = _.find(this.clickedCells, function (cl) {
        return cl.time === item.time && cl.day === d
      })
      if (cell) {
        this.clickedCells = _.reject(this.clickedCells, function (cl) {
          return cl.time === item.time && cl.day === d
        })
      } else {
        this.clickedCells.push({
          time: item.time,
          day: d
        })
      }
    },

    setWeek (item) {
      if (this.isWeekClicked(item)) {
        this.clickedCells = _.reject(this.clickedCells, function (cl) {
          return cl.time === item.time
        })
      } else {
        _.forEach(
          this.weekdays,
          function (d) {
            const cell = _.find(this.clickedCells, function (cl) {
              return cl.time === item.time && cl.day === d
            })
            if (!cell) this.toggleCell(d, item)
          }.bind(this)
        )
      }
    }
  }
})
</script>

<style scoped lang="scss">
  //thead {
  //  border: 1px solid #000;
  //}
  table {
    border-collapse: separate;
  }

  tr {
    height: 25px;

    ::v-deep td {
      &:not(:first-child) {
        background-color: #f6f6f6;

        &.clicked {
          background-color: #8378EC;
          color: white;
        }
        &:hover {
          background-color: #8378EC;
          color: white;
        }
        &.for-week {
          min-width: 65px;
        }
      }
    }
  }

  th,
  td {
    width: 60px;
    cursor: pointer;
    font-size: 12px;
    text-align: center;
  }

  .wtc-side-time {
    display: flex;
    align-items: center;
    margin-top: 5px;
  }
  .wtc-side-time span {
    font-size: 11px;
    white-space: nowrap;
  }
  .sub-wtc-side-time {
    display: flex;
    align-items: center;
    margin-top: 5px;
  }
  .sub-wtc-side-time span {
    font-size: 10px;
    color: #949494;
    white-space: nowrap;
    margin-left: 11px;
  }
  .wtc-side-time img {
    opacity: 0.4;
    transform: rotate(-90deg);
    margin-right: 4px;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }
  .wtc-row.sub {
    display: none;
  }
  @media (max-width: 690px) {
    .wtc-table-wrapper {
      overflow-x: auto;
    }
    th, td {
      width: 45px;
      min-width: 45px;
    }
  }
</style>
