export const CONVERSION_WINDOW_OPTIONS = {
  90: '90 days',
  60: '60 days',
  30: '30 days',
  28: '4 weeks',
  21: '3 weeks',
  14: '2 weeks',
  7: '1 week'
}

export const VIEW_THROUGH_CONVERSION_WINDOW = {
  30: '30 days',
  28: '4 weeks',
  21: '3 weeks',
  14: '2 weeks',
  7: '1 week',
  3: '3 days',
  1: '1 day'
}

export const ATTRIBUTION_MODEL = {
  GOOGLE_ADS_LAST_CLICK: 'Last click',
  GOOGLE_SEARCH_ATTRIBUTION_FIRST_CLICK: 'First click',
  GOOGLE_SEARCH_ATTRIBUTION_LINEAR: 'Linear',
  GOOGLE_SEARCH_ATTRIBUTION_TIME_DECAY: 'Time decay',
  GOOGLE_SEARCH_ATTRIBUTION_POSITION_BASED: 'Position-based',
  GOOGLE_SEARCH_ATTRIBUTION_DATA_DRIVEN: 'Data-driven (recommended)'
}

export const CATEGORIES = {
  PURCHASE: 'Purchase',
  ADD_TO_CART: 'Add to cart',
  BEGIN_CHECKOUT: 'Begin checkout',
  SUBSCRIBE_PAID: 'Subscribe',
  CONTACT: 'Contact',
  SUBMIT_LEAD_FORM: 'Submit lead form',
  BOOK_APPOINTMENT: 'Book appointment',
  SIGNUP: 'Sign-up',
  REQUEST_QUOTE: 'Request quote',
  GET_DIRECTIONS: 'Get directions',
  OUTBOUND_CLICK: 'Outbound click',
  PAGE_VIEW: 'Page view'

  // DOWNLOAD: 'Software download action (as for an app).',
  // PHONE_CALL_LEAD: "A call to indicate interest in an advertiser's offering.",
  // IMPORTED_LEAD: 'A lead conversion imported from an external source into Google Ads.',
  // ENGAGEMENT: 'A website engagement event such as long site time or a Google Analytics (GA) Smart Goal. Intended to be used for GA, Firebase, GA Gold goal imports.',
  // STORE_VISIT: 'A visit to a physical store location.',
  // STORE_SALE: 'A sale occurring in a physical store.',
  // QUALIFIED_LEAD: "A lead conversion imported from an external source into Google Ads, that has been further qualified by the advertiser (marketing/sales team). In the lead-to-sale journey, advertisers get leads, then act on them by reaching out to the consumer. If the consumer is interested and may end up buying their product, the advertiser marks such leads as 'qualified leads'.",
  // CONVERTED_LEAD: 'A lead conversion imported from an external source into Google Ads, that has further completed a chosen stage as defined by the lead gen advertiser.'
}

export const CURRENCY_CODES = ['AED', 'AFN', 'ALL', 'AMD', 'ANG', 'AOA', 'ARS', 'AUD', 'AWG', 'AZN', 'BAM', 'BBD', 'BDT', 'BGN', 'BHD', 'BIF', 'BMD', 'BND', 'BOB', 'BOV', 'BRL', 'BSD', 'BTN', 'BWP', 'BYN', 'BZD', 'CAD', 'CDF', 'CHE', 'CHF', 'CHW', 'CLF', 'CLP', 'COP', 'COU', 'CRC', 'CUC', 'CUP', 'CVE', 'CZK', 'DJF', 'DKK', 'DOP', 'DZD', 'EGP', 'ERN', 'ETB', 'EUR', 'FJD', 'FKP', 'GBP', 'GEL', 'GHS', 'GIP', 'GMD', 'GNF', 'GTQ', 'GYD', 'HKD', 'HNL', 'HRK', 'HTG', 'HUF', 'IDR', 'ILS', 'INR', 'IQD', 'IRR', 'ISK', 'JMD', 'JOD', 'JPY', 'KES', 'KGS', 'KHR', 'KMF', 'KPW', 'KRW', 'KWD', 'KYD', 'KZT', 'LAK', 'LBP', 'LKR', 'LRD', 'LSL', 'LYD', 'MAD', 'MDL', 'MGA', 'MKD', 'MMK', 'MNT', 'MOP', 'MRU', 'MUR', 'MVR', 'MWK', 'MXN', 'MXV', 'MYR', 'MZN', 'NAD', 'NGN', 'NIO', 'NOK', 'NPR', 'NZD', 'OMR', 'PAB', 'PEN', 'PGK', 'PHP', 'PKR', 'PLN', 'PYG', 'QAR', 'RON', 'RSD', 'CNY', 'RUB', 'RWF', 'SAR', 'SBD', 'SCR', 'SDG', 'SEK', 'SGD', 'SHP', 'SLL / SLE', 'SOS', 'SRD', 'SSP', 'STN', 'SVC', 'SYP', 'SZL', 'THB', 'TJS', 'TMT', 'TND', 'TOP', 'TRY', 'TTD', 'TWD', 'TZS', 'UAH', 'UGX', 'USD', 'USN', 'UYI', 'UYU', 'UYW', 'UZS', 'VED', 'VES', 'VND', 'VUV', 'WST', 'XAF', 'XAG', 'XAU', 'XBA', 'XBB', 'XBC', 'XBD', 'XCD', 'XDR', 'XOF', 'XPD', 'XPF', 'XPT', 'XSU', 'XTS', 'XUA', 'XXX', 'YER', 'ZAR', 'ZMW', 'ZWL']
