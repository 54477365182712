






































































































import Vue from 'vue'

import TriggerGroup from './TriggerGroup.vue'
import CustomMetric from './CustomMetric.vue'
import ConversionAction from './ConversionAction/ConversionAction.vue'
import ConversionActionList from '@/components/strategies/Rule/TaskWrapper/ConversionAction/ConversionActionList.vue'
import _ from 'lodash'
import {
  ENTITY_TYPE_AD,
  ENTITY_TYPE_ADSET,
  ENTITY_TYPE_CAMPAIGN,
  ENTITY_TYPE_CAMPAIGN_GROUP,
  ENTITY_TYPE_EXTENSION,
  ENTITY_TYPE_SEARCH_TERM,
  ENTITY_TYPE_KEYWORD,
  GOOGLE_SERVICE,
  LINKEDIN_SERVICE,
  METRIC_TYPE_COMPLEX,
  METRIC_TYPE_CUSTOM,
  METRIC_TYPE_SIMPLE
} from '@/constants/FbAutomatedRule'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import IRulesMeta from '@/models/IRulesMeta'
import ConversionDropdownOptions
  from '@/components/strategies/Rule/TaskWrapper/ConversionAction/ConversionDropdownOptions.vue'

export default Vue.extend({
  name: 'TriggerSection',
  props: {
    level: {
      required: true
    },
    rule: {
      required: true,
      type: Object
    },
    ruleId: {
      required: true
    },
    condition: {
      required: true,
      type: Object
    },
    conditionGroup: {
      required: true,
      type: Object
    },
    demoRule: {
      required: false,
      default: false,
      type: Boolean
    }
  },
  components: {
    ConversionDropdownOptions,
    TriggerGroup,
    // eslint-disable-next-line vue/no-unused-components
    CustomMetric,
    // eslint-disable-next-line vue/no-unused-components
    ConversionAction,
    ConversionActionList
  },
  mixins: [],
  data () {
    return {
      itemId: this.condition.uid,
      search_trigger: null,
      showDropdown: false
    }
  },
  created () {
    if (!this.condition.entity_type) {
      this.condition_entity_type = Object.keys(this.metric_entity_level_types)[0]
    }
  },
  watch: {
    selectedMetric (v) {
      this.removeErr({ key: this.condition.id, field: 'metric' }).then(() => {
        const level = this.condition_entity_type
        if (v && (typeof v.available === 'object')) {
          if (v.available.length === 1) {
            this.condition_entity_type = this.metric_available_to_condition_entity_type(v.available[0])
          } else if (!level || !v.available.includes(this.rule_entity_type_to_metric_available(level))) {
            this.addError({
              title: 'Condition metric error',
              detail: ['Please select entity type for this metric'],
              source: { parameter: 'conditions.' + this.condition.id + '.metric' }
            })
          }
        }
      })
    },
    customMetricId () {
      this.removeErr({ key: this.condition.id, field: 'metric' })
    }
  },
  computed: {
    ...mapState('rulesMeta', {
      orderedTriggers: (state: IRulesMeta) =>
        _.orderBy(state.triggers, 'id', 'asc')
    }),

    ...mapGetters({
      selectedCustomMetric: 'rulesMeta/selectedCustomMetric',
      selectedCustomMetricStrategies: 'strategy/selectedCustomMetric',
      getTimeRange: 'rulesMeta/getTimeRange',
      getError: 'rules/getError',
      getConditionItemError: 'rules/getConditionItemError',
      currentService: 'adService/getService',
      metricAvailable: 'rulesMeta/metricAvailable'
    }),
    ...mapGetters(['get_width']),
    ...mapGetters('adAccounts', ['getById']),
    selectedMetricKey: {
      get () {
        return this.condition.metric
      },
      set (v) {
        this.saveConditionProp('metric', v)
        this.saveConditionProp('metric_type', METRIC_TYPE_SIMPLE)
      }
    },
    isSingleRule () {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return !!Object.values(this.rule.conditions?.conditions).filter(v => !!v?.conditions).length
    },
    customMetricId: {
      get () {
        return this.condition.custom_metric_id
      },
      set (v) {
        this.saveConditionProp('custom_metric_id', v)
        this.saveConditionProp('metric_type', METRIC_TYPE_CUSTOM)
      }
    },
    dropupClassCondition () {
      return (this.get_width > 690 ||
          ((this.get_width < 690 && this.level !== 1) || (this.get_width < 690 && !this.isSingleRule)) ||
          (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            Object.values(this.rule.conditions?.conditions).map(v => v)[Object.values(this.rule.conditions?.conditions).map(v => v.level).length - 1].id === this.condition.id &&
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            !!Object.values(this.rule.conditions?.conditions).map(v => v)[Object.values(this.rule.conditions?.conditions).map(v => v.level).length - 1]
          )) &&
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        !(Object.values(this.conditionGroup?.conditions)[0].id === this.condition.id && this.isSingleRule)
    },
    selectedMetric () {
      return this.$store.getters['rulesMeta/selectedTrigger'](
        String(this.selectedMetricKey)
      )
    },

    selectedMetricTitle () {
      return this.selectedMetric ? this.selectedMetric.name : null
    },
    selectedCustomMetricTitle () {
      const selectmetric = this.selectedCustomMetric(this.customMetricId)
      return selectmetric ? selectmetric.name : null
    },
    filteredTriggers () {
      if (this.search_trigger) {
        return _.filter(this.orderedTriggers, group => {
          return (
            _.filter(
              group.fields,
              trigger =>
                trigger.name
                  .toLowerCase()
                  .indexOf(this.search_trigger.toLowerCase()) !== -1
            ).length > 0
          )
        })
      } else {
        return this.orderedTriggers
      }
    },
    condition_metric_type_simple () {
      return this.condition.metric_type === METRIC_TYPE_SIMPLE
    },
    condition_metric_type_complex () {
      return this.condition.metric_type === METRIC_TYPE_COMPLEX
    },
    condition_metric_type_custom () {
      return this.condition.metric_type === METRIC_TYPE_CUSTOM
    },
    metric_entity_level_types () {
      switch (this.currentService) {
        case LINKEDIN_SERVICE:
          switch (this.rule.entity_type) {
            case ENTITY_TYPE_CAMPAIGN:
              return { [ENTITY_TYPE_CAMPAIGN_GROUP]: ENTITY_TYPE_CAMPAIGN_GROUP }
            case ENTITY_TYPE_ADSET:
              return {
                [ENTITY_TYPE_CAMPAIGN]: ENTITY_TYPE_CAMPAIGN,
                [ENTITY_TYPE_CAMPAIGN_GROUP]: ENTITY_TYPE_CAMPAIGN_GROUP
              }
            case ENTITY_TYPE_AD:
              return {
                [ENTITY_TYPE_AD]: ENTITY_TYPE_AD,
                [ENTITY_TYPE_CAMPAIGN]: ENTITY_TYPE_CAMPAIGN,
                [ENTITY_TYPE_CAMPAIGN_GROUP]: ENTITY_TYPE_CAMPAIGN_GROUP
              }
            default:
              return []
          }
        case GOOGLE_SERVICE:
          switch (this.rule.entity_type) {
            case ENTITY_TYPE_CAMPAIGN:
              return { [ENTITY_TYPE_CAMPAIGN]: ENTITY_TYPE_CAMPAIGN }
            case ENTITY_TYPE_ADSET:
              return {
                [ENTITY_TYPE_ADSET]: ENTITY_TYPE_ADSET,
                [ENTITY_TYPE_CAMPAIGN]: ENTITY_TYPE_CAMPAIGN
              }
            case ENTITY_TYPE_AD:
              return {
                [ENTITY_TYPE_AD]: ENTITY_TYPE_AD,
                [ENTITY_TYPE_ADSET]: ENTITY_TYPE_ADSET,
                [ENTITY_TYPE_CAMPAIGN]: ENTITY_TYPE_CAMPAIGN
              }
            case ENTITY_TYPE_KEYWORD:
              return {
                [ENTITY_TYPE_KEYWORD]: ENTITY_TYPE_KEYWORD,
                [ENTITY_TYPE_ADSET]: ENTITY_TYPE_ADSET,
                [ENTITY_TYPE_CAMPAIGN]: ENTITY_TYPE_CAMPAIGN
              }
            case ENTITY_TYPE_EXTENSION:
              return {
                [ENTITY_TYPE_EXTENSION]: ENTITY_TYPE_EXTENSION,
                [ENTITY_TYPE_CAMPAIGN]: ENTITY_TYPE_CAMPAIGN
              }
            case ENTITY_TYPE_SEARCH_TERM:
              return {
                [ENTITY_TYPE_SEARCH_TERM]: ENTITY_TYPE_SEARCH_TERM,
                [ENTITY_TYPE_ADSET]: ENTITY_TYPE_ADSET,
                [ENTITY_TYPE_CAMPAIGN]: ENTITY_TYPE_CAMPAIGN
              }
            default:
              return []
          }
        default:
          switch (this.rule.entity_type) {
            case ENTITY_TYPE_CAMPAIGN:
              return { [ENTITY_TYPE_CAMPAIGN]: ENTITY_TYPE_CAMPAIGN }
            case ENTITY_TYPE_ADSET:
              return {
                [ENTITY_TYPE_ADSET]: ENTITY_TYPE_ADSET,
                [ENTITY_TYPE_CAMPAIGN]: ENTITY_TYPE_CAMPAIGN
              }
            case ENTITY_TYPE_AD:
              return {
                [ENTITY_TYPE_AD]: ENTITY_TYPE_AD,
                [ENTITY_TYPE_ADSET]: ENTITY_TYPE_ADSET,
                [ENTITY_TYPE_CAMPAIGN]: ENTITY_TYPE_CAMPAIGN
              }
            default:
              return []
          }
      }
    },
    condition_entity_type: {
      get () {
        return this.condition.entity_type
      },
      set (v: string) {
        this.removeErr({ key: this.condition.id, field: 'metric' })
        this.saveConditionProp('entity_type', v)
        if (this.selectedMetric && this.selectedMetric.available && !this.metricAvailable({
          trigger: this.selectedMetric,
          metric_entity_type: v,
          service: this.currentService
        })) {
          this.resetMetric()
        }
      }
    },
    adAccount () {
      return this.getById(this.rule.ad_account_id)
    }
  },
  methods: {
    ...mapMutations('rulesMeta', ['setErrors']),
    ...mapActions('rules', ['setConditionProp', 'addError', 'removeErr']),
    rule_entity_type_to_metric_available (type) {
      // map rule entity type to metric available field values
      switch (this.currentService) {
        case LINKEDIN_SERVICE:
          switch (type) {
            case ENTITY_TYPE_CAMPAIGN_GROUP:
              return 'campaign'
            case ENTITY_TYPE_CAMPAIGN:
              return 'ad_set'
            case ENTITY_TYPE_AD:
              return 'ad'
            default:
              return type.toLowerCase()
          }
        default:
          switch (type) {
            case ENTITY_TYPE_CAMPAIGN:
              return 'campaign'
            case ENTITY_TYPE_ADSET:
              return 'ad_set'
            case ENTITY_TYPE_AD:
              return 'ad'
            case ENTITY_TYPE_EXTENSION:
              return 'extension'
            case ENTITY_TYPE_SEARCH_TERM:
              return 'search_term'
            case ENTITY_TYPE_KEYWORD:
              return 'keyword'
            default:
              return type.toLowerCase()
          }
      }
    },
    metric_available_to_condition_entity_type (type) {
      // map metric available field values to rule entity type
      switch (this.currentService) {
        case LINKEDIN_SERVICE:
          switch (type) {
            case 'campaign':
              return ENTITY_TYPE_CAMPAIGN_GROUP
            case 'ad_set':
              return ENTITY_TYPE_CAMPAIGN
            case 'ad':
              return ENTITY_TYPE_AD
            default:
              return type.toUpperCase()
          }
        default:
          switch (type) {
            case 'campaign':
              return ENTITY_TYPE_CAMPAIGN
            case 'ad_set':
              return ENTITY_TYPE_ADSET
            case 'ad':
              return ENTITY_TYPE_AD
            case 'extension':
              return ENTITY_TYPE_EXTENSION
            case 'search_term':
              return ENTITY_TYPE_SEARCH_TERM
            case 'keyword':
              return ENTITY_TYPE_KEYWORD
            default:
              return type.toUpperCase()
          }
      }
    },
    groupNotEmpty (group) {
      return (
        group.fields.filter(f =>
          this.$store.getters['rulesMeta/metricAvailable'](
            {
              metric_entity_type: this.condition_entity_type,
              trigger: f,
              service: this.currentService
            }
          )
        ).length > 0
      )
    },
    resetMetric () {
      this.selectedMetricKey = null
      this.$forceUpdate()
    },
    selectMetric (metric) {
      if (metric?.type === 'custom') {
        this.customMetricId = metric.id
      } else {
        this.selectedMetricKey = metric.field
      }
      this.$emit('select-metric', metric)
      this.$forceUpdate()
    },

    saveConditionProp (type, value) {
      this.setConditionProp({
        ruleId: this.ruleId,
        itemId: this.itemId,
        type: type,
        value: value
      })
    },

    showCustomMetricModal (metricId = null) {
      this.$modal.show(
        CustomMetric,
        {
          on_close: this.hide_modal,
          rule: this.rule,
          ruleId: this.ruleId,
          condition: this.condition,
          custom: this.selectedCustomMetric(metricId),
          itemId: this.itemId
        },
        {
          name: 'custom-metric',
          adaptive: true,
          height: 'auto',
          overlayTransition: 'linear',
          classes: 'CreateCustomMetricModal',
          width: 620
        }
      )
    },
    showConversionActionModal () {
      this.$modal.show(ConversionAction, {
        ad_account: this.adAccount
      }, {
        name: 'conversion-action-modal',
        adaptive: true,
        height: 'auto',
        overlayTransition: 'linear',
        classes: 'CreateCustomMetricModal',
        width: 800
      })
    },
    hide_modal (name) {
      this.$modal.hide(name)
    },
    metric_entity_level_class_active (v) {
      return (v === this.condition_entity_type)
    }
  }
})
