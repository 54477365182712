export const DAYS_OF_THE_WEEK = [
  {
    id: 'MONDAY',
    name: 'Monday',
    short: 'Mon'
  },
  {
    id: 'TUESDAY',
    name: 'Tuesday',
    short: 'Tu'
  },
  {
    id: 'WEDNESDAY',
    name: 'Wednesday',
    short: 'Wed'
  },
  {
    id: 'THURSDAY',
    name: 'Thursday',
    short: 'Th'
  },
  {
    id: 'FRIDAY',
    name: 'Friday',
    short: 'Fr'
  },
  {
    id: 'SATURDAY',
    name: 'Saturday',
    short: 'Sat'
  },
  {
    id: 'SUNDAY',
    name: 'Sunday',
    short: 'Su'
  }
]
