<template>
  <div class="block block-type-1 margin-top-4">
    <div class="schedule-title" v-if="!demoRule">
      <div>
        <h2 class="h2">Schedule</h2>
        <p class="text-small text-gray margin-top-3">Set how often rule conditions will be checked</p>
      </div>
    </div>
    <div v-if="!demoRule" class="schedule-settings d-flex align-items-start mt-4">
      <div class="title">
        <p>Rule checking frequency</p>
      </div>
      <div class="body d-flex">
        <VSelect
          :append-to-body="true"
          :options="continuouslyIntervals"
          :value="continuouslyIntervalValue"
          :disabled="scheduleType === customScheduleType"
          @input="selectContinuouslyInterval"
          :label="'label'"
          :min-width="250"
        >
          <template #option="{ option }">
            <span>{{ option.label }}</span>
          </template>
        </VSelect>

        <div class="schedule-switcher d-flex">
          <NewUiSwitcher
            :type="2"
            :checked="scheduleType === customScheduleType"
            @change="setScheduleType"
          ></NewUiSwitcher>
          <span class="schedule-switcher-label">Custom</span>
        </div>
      </div>
    </div>

    <div v-if="intervals && demoRule" class="schedule-container bg-gray-light">
      <div class="d-flex justify-content-between mb-2">
        <span> Selected rule checking time: </span>
      </div>

      <div
        class="schedule-item"
        v-for="(times, day) in selectedSchedule"
        :key="day"
      >
        <div>{{ day }}</div>
        <div>
          <span v-for="(time, index) in times.sort()" :key="index">
            {{ time }}
          </span>
        </div>
      </div>
    </div>
    <div v-if="scheduleType === customScheduleType">
      <SelectTime @done="intervals = $event" :intervals="intervals" :demoRule="demoRule"/>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import SelectTime from './SelectTime.vue'
import VSelect from '@/components/BaseComponents/VSelect/VSelect'
import NewUiSwitcher from '@/components/NewUIComponents/NewUiSwitcher'
import _ from 'lodash'
import moment from 'moment'
import { mapActions } from 'vuex'
import {
  SCHEDULE_CONTINUOUSLY_INTERVALS,
  SCHEDULE_TYPE_CONTINUOUSLY,
  SCHEDULE_TYPE_CUSTOM
} from '@/constants/FbAutomatedRule'

export default Vue.extend({
  name: 'Schedule',
  props: ['rule', 'demoRule'],
  components: { SelectTime, VSelect, NewUiSwitcher },
  data () {
    return {
      continuouslyIntervals: SCHEDULE_CONTINUOUSLY_INTERVALS
    }
  },
  watch: {
    selectedSchedule (val, prevVal) {
      if (!Object.keys(val).length && Object.keys(prevVal).length) {
        this.scheduleType = SCHEDULE_TYPE_CONTINUOUSLY
      }
    }
  },

  computed: {
    continuouslyIntervalValue: {
      get () {
        return this.rule.schedule_spec
          ? SCHEDULE_CONTINUOUSLY_INTERVALS.find(i => i.value === this.rule.schedule_spec.value)
          : null
      },
      set (v) {
        this.$store.commit('rules/setRuleProp', {
          id: this.rule.id,
          type: 'schedule_spec',
          value: {
            ...this.rule.schedule_spec,
            value: v.value
          }
        })
      }
    },
    customScheduleType () {
      return SCHEDULE_TYPE_CUSTOM
    },
    scheduleType: {
      get () {
        return this.rule.schedule_spec
          ? this.rule.schedule_spec.type
          : SCHEDULE_TYPE_CONTINUOUSLY
      },
      set (v) {
        this.saveSchedule({ type: v, schedule: null })
      }
    },

    intervals: {
      get () {
        if (!this.rule.schedule_spec) return null
        if (
          this.rule.schedule_spec.type === SCHEDULE_TYPE_CUSTOM &&
          this.rule.schedule_spec.schedule
        ) {
          return _.reduce(
            this.rule.schedule_spec.schedule,
            (acc, s) => {
              const duration = moment.duration(s.start_minute, 'minutes')
              acc.push({
                day: this.getDayName(s.days[0]),
                time: `${duration
                  .hours()
                  .toString()
                  .padStart(2, '0')}:${duration
                  .minutes()
                  .toString()
                  .padStart(2, '0')}`
              })
              return acc
            },
            []
          )
        } else return null
      },
      set (v) {
        this.saveSchedule({ schedule: this.calculateSchedule(v) })
        this.removeError('schedule_spec.schedule')
      }
    },

    selectedSchedule () {
      return _.reduce(
        this.intervals,
        (acc, i) => {
          const tmp = _.get(acc, i.day, [])
          tmp.push(i.time)
          _.set(acc, i.day, tmp)
          return acc
        },
        {}
      )
    }
  },

  methods: {
    ...mapActions('rules', ['removeError']),
    setScheduleType (v) {
      if (v) {
        this.scheduleType = SCHEDULE_TYPE_CUSTOM
      } else {
        this.scheduleType = SCHEDULE_TYPE_CONTINUOUSLY
      }
    },
    selectContinuouslyInterval (value) {
      this.continuouslyIntervalValue = value
    },
    getDayKey (day) {
      return ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].indexOf(day)
    },
    getDayName (number) {
      return ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'][number]
    },
    saveSchedule (v) {
      this.$store.commit('rules/setRuleProp', {
        id: this.rule.id,
        type: 'schedule_spec',
        value: {
          ...this.rule.schedule_spec,
          ...v
        }
      })
    },
    calculateSchedule (intervals) {
      return _.reduce(
        intervals,
        (acc, i) => {
          const m = moment.duration(i.time).asMinutes()
          acc.push({
            days: [this.getDayKey(i.day)],
            start_minute: m,
            end_minute: m
          })
          return acc
        },
        []
      )
    }
  }
})
</script>

<style lang="scss" scoped>
  .card {
    padding: 1.5rem;
    @media (max-width: 690px) {
      padding: 1rem;
    }
  }
  .schedule-title {
    span {
      color: $subtitle-middle
    }
  }
  .schedule-settings {
    flex-direction: column;
    .title {
      p {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: $dark-text;
      }
    }
    .body {
      margin-top: 8px;
      .schedule-switcher {
        margin-left: 4px;
        background: $gray-ultra-light;
        border-radius: 4px;
        padding: 8px 16px;
        &-label {
          margin-left: 12px;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: $dark-text;
        }
        @media (max-width: 768px) {
          margin-top: 12px;
          margin-left: 0;
        }
      }
      @media (max-width: 768px) {
        flex-direction: column;
        width: 100%;
      }
    }

  }

  .schedule-container {
    width: fit-content;
    max-width: 600px;
    padding: 1rem;
    margin-top: 1rem;
    border: 1px solid #d2d6de;
    border-radius: 3px;

    & .schedule-item {
      display: flex;
      align-items: center;
      font-size: 13px;
      min-height: 23px;
      border: 0.5px solid #d2d6de;
      background-color: white;
      border-radius: 3px;
      margin-bottom: 3px;

      & div:first-child {
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        width: 55px;
        text-align: center;
        border-right: 1px solid #d2d6de;
      }

      & div {
        & span {
          padding: 0px 8px;
        }
      }
    }
  }
</style>
