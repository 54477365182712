<template>
  <div class="custom-modal-content bg-gray-light">
    <scheduler
      v-if="loaded && !demoRule"
      :time_intervals="time_intervals"
      :sub_time="['15', '30', '45']"
      :demoRule="demoRule"
      v-model="selected_time_intervals"
    />
<!--    <div class="modal-footer d-flex align-items-center justify-content-end p-3">-->
<!--      <button-->
<!--        id="saveWeekTimeBtn"-->
<!--        type="button"-->
<!--        class="standard-btn standard-btn-primary"-->
<!--        @click="done"-->
<!--      >-->
<!--        Done-->
<!--      </button>-->
<!--    </div>-->
  </div>
</template>

<script>
import Vue from 'vue'
import _ from 'lodash'
import Scheduler from './Scheduler/SchedulerTable.vue'
export default Vue.extend({
  name: 'SelectTime',
  components: { Scheduler },
  props: ['intervals', 'demoRule'],
  data () {
    return {
      loaded: false,
      time_intervals: [
        '12 am',
        '01 am',
        '02 am',
        '03 am',
        '04 am',
        '05 am',
        '06 am',
        '07 am',
        '08 am',
        '09 am',
        '10 am',
        '11 am',
        '12 pm',
        '01 pm',
        '02 pm',
        '03 pm',
        '04 pm',
        '05 pm',
        '06 pm',
        '07 pm',
        '08 pm',
        '09 pm',
        '10 pm',
        '11 pm'
      ],
      selected_time_intervals: null
    }
  },
  watch: {
    selected_time_intervals (v) {
      this.selected_time_intervals = v
      this.$emit('done', this.selected_time_intervals)
    }
  },
  mounted () {
    this.selected_time_intervals = _.cloneDeep(this.intervals)
    this.loaded = true
  },
  methods: {
    close_modal () {
      this.$attrs.on_close('select-time')
    },
    done () {
      // this.$attrs.save_intervals(this.selected_time_intervals)
      // this.$attrs.on_close('select-time')
      this.$emit('done', this.selected_time_intervals)
    }
  }
})
</script>

<style lang="scss" scoped>
  .header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #dee2e6;
    padding: 15px;

    span {
      font-weight: 600;
      font-size: 16px;
      color: #666;
    }

    button {
      background-color: transparent;
      border-color: transparent;
      outline: none;
    }
  }

  .modal-body {
    max-height: 550px;
    overflow-y: scroll;

    ::v-deep * {
      user-select: none;
    }
  }
</style>
