<template>
  <new-ui-modal
    title="Conversion actions list"
    :name="'conversion-action-list-modal'"
    :width="'800'"
    :overflow="true"
    :show-footer="false"
    @before-close="page = 1"
  >
    <template #body>
      <p class="h1">{{conversionAction.length}} conversions action</p>
      <div class="table-responsive table-type-2 mt-4">
        <table class="table table-adaptive table-striped">
          <thead>
          <tr>
            <th class="col sticky-col last-col">Name</th>
            <th scope="col">Status</th>
            <th scope="col">Category</th>
            <th scope="col">Count</th>
            <th class="green-th" scope="col">Actions</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in conversionAction" :key="item.id">
            <td data-title="Name" class="sticky-col last-col">
              <div class="td-content">
                <div class="name-block d-flex flex-column">
                  <p>{{item.name || ''}}</p>
                  <p class="p-small text-gray text-nowrap margin-top-1">{{item.type || ''}}</p>
                </div>
              </div>
            </td>
            <td data-title="Status">
              <div class="td-content">
                <p class="padding-x-2 p-small">{{item.status || ''}}</p></div>
            </td>
            <td data-title="Category">
              <div class="td-content">
                <span>{{item.category || ''}}</span>
              </div>
            </td>
            <td data-title="Count">
              <div class="td-content">
                <span>{{item.value_settings && item.value_settings.defaultValue || ''}}</span>
              </div>
            </td>
            <td data-title="Actions">
              <ConversionTableAction :item="item"
                                     @tagSetupModal="tagSetupModal($event)"
                                     @updateModal="updateModal($event)"
                                     @deleteConversion="deleteConversion($event)"
              />
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div
        class="search-result-pagination d-flex justify-content-between mt-3"
        style="width: 100%"
      >
        <VSelect
          :max-width="64"
          :min-width="64"
          :append-to-body="true"
          :options="[5, 10, 15]"
          :value="per_page"
          @input="per_page = $event"
        />
        <new-ui-pagination
          :page="pagination.current_page || 1"
          :page-count="(pagination && pagination.total_pages) || 1"
          color="violet"
          small
          @change-page="page = $event"
        />
      </div>
    </template>
  </new-ui-modal>
</template>

<script>
import Vue from 'vue'
import NewUiModal from '@/components/NewUIComponents/NewUiModal'
import { mapActions, mapGetters } from 'vuex'
import VSelect from '@/components/BaseComponents/VSelect/VSelect'
import NewUiPagination from '@/components/NewUIComponents/NewUiPagination'
import ConversionAction from '@/components/strategies/Rule/TaskWrapper/ConversionAction/ConversionAction.vue'
import TagSetupConversionAction from '@/components/strategies/Rule/TaskWrapper/ConversionAction/TagSetupConversionAction'
import ConversionTableAction from '@/components/strategies/Rule/TaskWrapper/ConversionAction/ConversionTableAction'
export default Vue.extend({
  name: 'ConversionActionList',
  props: {
    ad_account: {
      type: Object
    }
  },
  // eslint-disable-next-line vue/no-unused-components
  components: { ConversionTableAction, NewUiPagination, NewUiModal, ConversionAction, TagSetupConversionAction, VSelect },
  computed: {
    ...mapGetters('googleConversionActions', ['getConversionAction', 'getById', 'getConversionActionPagination']),
    pagination () {
      return this.getConversionActionPagination
    },
    conversionAction () {
      return this.getConversionAction || []
    }
  },
  data () {
    return {
      page: 1,
      per_page: 10
    }
  },
  methods: {
    ...mapActions('googleConversionActions', ['deleteConversionAction', 'loadConversionAction']),
    ...mapActions('notifications', ['addExceptions']),
    async updateModal (id) {
      await this.$modal.hide('conversion-action-list-modal')
      this.$modal.show(ConversionAction, {
        ad_account: this.ad_account,
        conversion_action: this.getById(id)
      }, {
        name: 'conversion-action-modal',
        adaptive: true,
        height: 'auto',
        overlayTransition: 'linear',
        classes: 'CreateCustomMetricModal',
        width: 800
      })
    },
    deleteConversion (id) {
      this.deleteConversionAction(id)
        .then(() => {
          this.loadConversionAction({ ad_account_id: this.ad_account.id, page: this.page, per_page: this.per_page })
        })
        .catch(er => {
          this.addExceptions(er.response.data)
        })
    },
    async tagSetupModal (id) {
      await this.$modal.hide('conversion-action-list-modal')
      this.$modal.show(TagSetupConversionAction, {
        conversion_action: this.getById(id)
      }, {
        name: 'tag-setup-conversion-action',
        adaptive: true,
        height: 'auto',
        width: 800
      })
    }
  },
  watch: {
    page () {
      this.loadConversionAction({ ad_account_id: this.ad_account.id, page: this.page, per_page: this.per_page })
    },
    per_page () {
      this.loadConversionAction({ ad_account_id: this.ad_account.id, page: this.page, per_page: this.per_page })
    }
  }
})
</script>

<style scoped lang="scss">
.search-result-pagination {
  //::v-deep .vs__selected {
  //  margin: 9px 2px 0;
  //}
}
</style>
