<template>
  <div class="tree-select-wrapper">
    <div class="tree-select-body">
      <treeselect
        :style="{'min-width': minWidth + 'px'}"
        ref="treeselect"
        :openDirection="'auto'"
        v-model="value"
        :multiple="false"
        :always-open="false"
        :clearable="false"
        :searchable="searchable"
        :closeOnSelect="true"
        :disable-branch-nodes="true"
        @search-change="searchChange"
        :options="options"
        :default-expand-level="searchValue.length > 0 ? 0 : 2"
        search-nested
      >
        <template #before-list>
          <div style="position: sticky; top: 0;width: 100%; background: #fff; z-index: 100">
            <div v-if="$slots['custom-header']" style="padding: 8px 8px 0 8px">
              <slot name="custom-header"></slot>
            </div>
            <label v-if="!searchable && customSearch" style="width: 100%; padding: 12px 8px; border-bottom: 1px solid #CED4DA;">
              <input @click.stop="changeSearchValue()"
                     class="form-control input-type-1"
                     @input="changeInput"
                     ref="inp"
                     type="text"
                     v-model="searchValue"
                     placeholder="Search...">
            </label>
          </div>
        </template>
      </treeselect>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'

import Treeselect from '@riophae/vue-treeselect'

export default Vue.extend({
  name: 'TreeSelect',
  data () {
    return {
      value: null,
      searchValue: ''
    }
  },
  components: {
    Treeselect
  },
  props: {
    options: {
      type: Array
    },
    minWidth: {
      type: Number,
      default: 170
    },
    searchable: {
      type: Boolean,
      default: false
    },
    customSearch: {
      type: Boolean,
      default: true
    },
    defaultValue: {
      default: null
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value (v) {
      if (v !== this.defaultValue) {
        this.$emit('change-value', v)
      }
    },
    defaultValue (v) {
      if (v !== null) {
        this.value = v
      }
    }
  },
  methods: {
    changeInput () {
      this.$emit('searching', this.searchValue)
    },
    changeSearchValue () {
      this.$refs.inp.focus()
      this.$refs.treeselect.toggleMenu()
    },
    searchChange (ev) {
      console.log(ev)
    }
  },
  mounted () {
    this.value = this.defaultValue
  }
})
</script>
<style lang="scss">
.tree-select-wrapper {
  .tree-select-open-button {}
  .tree-select-body {}
}
</style>
