<template>
  <section class="time-range-section padding-x-3">
    <TreeSelect
      v-if="timeRange"
      :options="openedRangesList"
      :default-value="timeRange.field"
      :min-width="100"
      @searching="searchValue = $event"
      @change-value="selectTimeRange($event)">
    </TreeSelect>
<!--    <button data-bs-toggle="dropdown"-->
<!--            aria-expanded="false"-->
<!--            :class="{'default': theme === 'default', 'form-control standard-btn custom-metric': theme === 'custom_metric'}">-->
<!--      <span>{{ timeRange.name }}</span>-->
<!--      <svg v-if="theme === 'custom_metric'"-->
<!--        style="margin-right: -7px"-->
<!--        class="ms-2"-->
<!--        width="11"-->
<!--        height="7"-->
<!--        viewBox="0 0 9 6"-->
<!--        fill="none"-->
<!--        xmlns="http://www.w3.org/2000/svg"-->
<!--      >-->
<!--        <path-->
<!--          d="M3.6 5.4L0.2 2C-0.1 1.8-0.1 1.4 0.2 1.2L0.7 0.6C1 0.4 1.4 0.4 1.6 0.6L4 3 6.4 0.6C6.6-->
<!--              0.4 7 0.4 7.3 0.6L7.8 1.2C8.1 1.4 8.1 1.8 7.8 2L4.4 5.4C4.2 5.6 3.8 5.6 3.6 5.4Z"-->
<!--          fill="#555"-->
<!--        />-->
<!--      </svg>-->
<!--    </button>-->
<!--    <div class="dropdown-menu md py-3 pl-3 p-2" v-if="!demoRule">-->
<!--      <div class="dropdown-select-content m-0 h-100">-->
<!--        <template v-for="group in openedRanges">-->
<!--          <div :key="group.id">-->
<!--            <button-->
<!--              v-if="group.group"-->
<!--              class="d-flex align-items-center mb-2 pl-0 bold"-->
<!--              @click.stop="-->
<!--              group.open = !group.open-->
<!--              $forceUpdate()-->
<!--            "-->
<!--            >-->
<!--              <div-->
<!--                class="dropdown-result-icon mr-2"-->
<!--                :class="{ expanded: group.open }"-->
<!--              >-->
<!--                <img src="../../../../assets/icons/chevron-icon.svg" alt="">-->
<!--              </div>-->
<!--              <p class="group">{{ group.group }}</p>-->
<!--            </button>-->
<!--            <button-->
<!--              v-for="range in group.fields"-->
<!--              :key="range.field"-->
<!--              :style="{ display: group.open ? 'block' : 'none' }"-->
<!--              class="item"-->
<!--              :class="{ chosen: timeRange.field === range.field }"-->
<!--              @click="selectTimeRange({ field: range.field, name: range.name })"-->
<!--            >-->
<!--              <img v-if="timeRange.field === range.field"-->
<!--                   src="../../../../assets/icons/success-icon.svg" alt="">-->
<!--              <span>{{ range.name }}</span>-->
<!--            </button>-->
<!--          </div>-->
<!--        </template>-->
<!--      </div>-->
<!--    </div>-->
  </section>
</template>

<script>
import Vue from 'vue'
import _ from 'lodash'
import TreeSelect from '@/components/BaseComponents/TreeSelect/TreeSelect.vue'
import { mapState } from 'vuex'

export default Vue.extend({
  name: 'Timeframe',
  components: {
    TreeSelect
  },
  props: {
    time_range: {
      default: null
    },
    demoRule: {
      default: false
    },
    theme: {
      type: String,
      default: 'default'
    }
  },
  data () {
    return {
      timeRange: null,
      searchValue: ''
    }
  },

  mounted () {
    if (this.time_range && this.allRanges.length > 0) {
      this.timeRange = this.allRanges.find(
        range => range.field === this.time_range
      )
    } else {
      this.$emit('set_time_range', 'LIFETIME')
    }
  },

  watch: {
    // searchValue () {
    //   alert('change')
    // },
    ranges () {
      if (this.time_range) {
        this.timeRange = this.allRanges.find(
          range => range.field === this.time_range
        )
      } else {
        this.$emit('set_time_range', 'LIFETIME')
      }
    },
    time_range (newVal) {
      if (newVal) {
        this.timeRange = this.allRanges.find(
          range => range.field === newVal
        )
      } else {
        this.$emit('set_time_range', 'LIFETIME')
      }
    }
  },

  computed: {
    openedRangesList () {
      const arr = []
      if (this.openedRanges && this.openedRanges.length > 0) {
        this.openedRanges.forEach(v => {
          const obj = {
            id: v.id,
            children: v.actions
          }
          if (v.group) {
            const childrenArr = []
            v.fields.forEach(t => {
              if (this.searchValue.length > 0) {
                if (t.name.toLowerCase().includes(this.searchValue.toLowerCase())) {
                  childrenArr.push(
                    {
                      field: t.field,
                      name: t.name,
                      label: t.name,
                      id: t.field
                    }
                  )
                }
              } else {
                childrenArr.push(
                  {
                    field: t.field,
                    name: t.name,
                    label: t.name,
                    id: t.field
                  }
                )
              }
            })
            obj.label = v.group
            obj.children = childrenArr
            arr.push(obj)
          } else {
            const emptyArr = []
            v.fields.forEach(v => {
              emptyArr.push({
                name: v.name,
                label: v.name,
                field: v.field,
                id: v.field
              })
            })
            emptyArr.forEach(t => {
              if (this.searchValue.length > 0) {
                if (t.name.toLowerCase().includes(this.searchValue.toLowerCase())) {
                  arr.unshift(t)
                }
              } else {
                arr.unshift(t)
              }
            })
            // if (this.searchValue.length > 0) {
            //   v.fields.forEach(t => {
            //     if (t.name.toLowerCase().includes(this.searchValue.toLowerCase())) {
            //       arr.unshift({
            //         field: t.field,
            //         name: t.name,
            //         label: t.name,
            //         id: t.field
            //       })
            //     }
            //   })
            // } else {
            //   v.fields.forEach(t => {
            //     arr.unshift({
            //       field: t.field,
            //       name: t.name,
            //       label: t.name,
            //       id: t.field
            //     })
            //   })
            // }
          }
        })
      }
      return arr.filter(v => {
        if (v.children) {
          return v.children.length > 0
        } else {
          return v
        }
      })
    },
    ...mapState({
      ranges: state => state.rulesMeta.timeRanges
    }),

    // selectedRange(){
    //   return this.time_range ?
    // },

    openedRanges () {
      return this.ranges && this.ranges.map(group => ({
        ...group,
        open: true
      }))
    },

    allRanges () {
      return _.reduce(
        this.ranges,
        (acc, r) => {
          return acc.concat(r.fields)
        },
        []
      )
    }
  },

  methods: {
    selectTimeRange (field) {
      if (field) {
        this.timeRange = field
        this.$emit('set_time_range', field)
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.time-range-section {
  justify-content: center;
  border-right: 1px solid #CED4DA;
  position: relative;
  font-size: 14px;
  min-width: 200px;
  max-width: 200px;
  width: 100%;
  white-space: nowrap;
  @media (max-width: 768px) {
    border-right: 0;
    border-left: 1px solid #CED4DA;
    border-radius: 0;
  }
  > button {
    width: 100%;
    height: 100%;
    text-align: left;
    justify-content: flex-start !important;
    padding-inline: 4px;
  }
  > button.custom-metric {
    font-size: 16px;
  }
  .group {
    font-weight: 600;
    font-size: 14px;
    margin: 5px 0;
  }
  .dropdown-menu {
    min-width: 240px !important;
    max-height: 256px;
    overflow: auto;
    // position: absolute;
    left: 0;
    bottom: 40px;
    background: #fff;
    box-shadow: 0 0 10px #00000020;
    border-radius: 3px;
    font-size: 0.9em;
    .dropdown-select-content {
      height: 180px;
      overflow-y: auto;
    }
    .item {
      margin: 0 0 10px 15px;
      width: auto!important;
      span {
        margin: 0 0 0 5px;
        font-size: 14px;
      }
    }
    .chosen {
      color: $blue-primary;
      font-weight: 600;
      position: relative;
      img {
        position: absolute;
        left: -8px;
        top: 3px;
        max-width: 9px;
      }
    }
  }
}
@media (max-width: 1560px) {
  .time-range-section {
    width: 100%;
    max-width: 150px;
    min-width: 150px;
  }
}
@media (max-width: 1290px) {
  .time-range-section {
    width: 100%;
    max-width: 120px;
    min-width: 120px;
  }
}
//@media (max-width: 1290px) {
//  .time-range-section {
//    width: 100%;
//    max-width: 90px;
//    min-width: 90px;
//  }
//}
  /*@media (max-width: 1156px) {*/
  /*  .time-range-section {*/
  /*    width: 100%;*/
  /*    max-width: 150px;*/
  /*    min-width: 130px;*/
  /*  }*/
  /*}*/
@media (max-width: 1024px) {
  .time-range-section {
    width: 100%;
    // max-width: 150px;
    min-width: 150px;
    // border: 1px solid #EAEBEE;
    margin-top: 7px;
    max-width: 100%;
  }
}
  @media (max-width: 690px) {
    .time-range-section {
      margin-top: 3px;
      max-width: 100%;
      border-radius: 0;
    }
  }
</style>
