







































































































































































import ExpressionTask from './ExpressionTask.vue'
import Switcher from '../../../../components/Switcher.vue'
import BaseAlert from '@/components/BaseComponents/BaseNotification/BaseAlert.vue'
import VSelect from '@/components/BaseComponents/VSelect/VSelect.vue'

import { ENTITY_TYPE_ADSET, LINKEDIN_SERVICE } from '@/constants/FbAutomatedRule'
import _ from 'lodash'
import { mapGetters, mapState, mapMutations } from 'vuex'

export default {
  name: 'TaskActionParameters',
  components: {
    BaseAlert,
    ExpressionTask,
    Switcher,
    VSelect
  },
  props: ['rule', 'action', 'campaignsBudgetWarning', 'adsetsBudgetWarning', 'demoRule'],
  data () {
    return {
      dropdownValues: {},
      activeSet: 0,
      toggles: ['value', 'strategy', 'optimization'],
      toggleDropdownGroups: {}
    }
  },

  created () {
    if (this.rule && this.rule.execution_spec) {
      this.dropdown_action_inputs.forEach(input => {
        this.dropdownValues[input.key] = this.rule.execution_spec.options[input.key]
      })
    }
  },
  mounted () {
    if (this.rule.data && this.rule.data.set) this.setNewSet(this.rule.data.set)
  },

  watch: {
    action: function (action, prevAction) {
      if (this.ruleEdited && action.key !== prevAction.key) {
        this.setNewSet()
      }
      this.setStateProp({ prop: 'ruleEdited', value: true })
    }
  },
  computed: {
    ...mapState('rules', ['warnings', 'ruleEdited']),
    ...mapGetters({
      hasError: 'rules/hasError',
      error: 'rules/errorMsg',
      warning: 'rules/warningMsg',
      hasWarning: 'rules/hasWarning',
      adAccounts: 'adAccounts/getById',
      currentService: 'adService/getService',
      getAdSetByExternalId: 'campaigns/adSetByExternalId'
    }),
    isRuleEntitiesEmpty () {
      return this.rule.entities && !Object.values(this.rule.entities).find((v: string[]) => v.length > 0)
    },
    dropdown_action_inputs () {
      return this.action.sets.reduce((acc, set) => {
        return [
          ...acc,
          ...set.reduce((sub_acc, sub_set) => {
            return [
              ...sub_acc,
              ...sub_set.inputs.filter(input => {
                return input.type === 'dropdown'
              })
            ]
          }, [])
        ]
      }, [])
    },
    accountCurrency (): string {
      return _.get(this.adAccounts(this.rule.ad_account_id), 'currency', 'USD')
    },
    action_warnings () {
      // после рефактора это должно перекочевать в компонент отдельных экшнов, названия экшнов должны быть в константах
      const warnings = []
      this.campaignsBudgetWarning && warnings.push(this.campaignsBudgetWarning)
      this.adsetsBudgetWarning && warnings.push(this.adsetsBudgetWarning)
      if (this.currentService === LINKEDIN_SERVICE && this.rule.entity_type === ENTITY_TYPE_ADSET && this.rule.entities?.ad_sets) {
        const adSets = this.rule.entities.ad_sets.map(a => this.getAdSetByExternalId(a)).filter(a => a)
        switch (this.rule.execution_spec.key) {
          case 'INC_DAILY_BUDGET':
          case 'DEC_DAILY_BUDGET':
          case 'SET_DAILY_BUDGET':
            if (adSets.some(a => a.daily_budget === null)) {
              warnings.push('One or more of the selected campaigns don\'t have have a daily budget.')
              return warnings
            }
            break
          case 'INC_LIFETIME_BUDGET':
          case 'DEC_LIFETIME_BUDGET':
          case 'SET_LIFETIME_BUDGET':
            if (adSets.some(a => a.lifetime_budget == null)) {
              warnings.push('One or more of the selected campaigns don\'t have have a lifetime budget.')
              return warnings
            }
            break
        }
      }
      return warnings
    },
    options () {
      return this.rule.execution_spec.options
    }
  },

  methods: {
    ...mapMutations('rules', [
      'setNestedProp',
      'setActionProp',
      'setRuleProp',
      'setStateProp',
      'addWarning',
      'removeWarning'
    ]),
    selectOptions (arr, type) {
      const select_options = []
      Object.keys(arr).forEach((v, index) => {
        const obj = {
          value: arr[v].value,
          toggle_set: arr[v].toggle_set,
          input_type: type,
          id: index,
          key: null
        }
        if (arr[v].value === '%currency%') {
          obj.value = v
          obj.key = this.accountCurrency
        } else {
          obj.value = v
          obj.key = arr[v].value
        }
        select_options.push(obj)
      })
      if (type === 'select') {
        return select_options
      }
      return arr
    },
    setDropdownValues (key, value) {
      this.dropdownValues[key] = value
      this.setActionProp({
        ruleId: this.rule.id,
        key: key,
        value: value.map(option => option.value)
      })
    },
    dropdownOptions (obj) {
      const options = []
      Object.keys(obj).forEach(v => {
        options.push({
          value: v,
          label: obj[v]
        })
      })
      return options
    },
    getActionSetInputByKey (set, key) {
      let input = null
      _.forEach(set, function (s) {
        input = s.inputs.find(i => i.key === key)
        return !input
      })
      return input
    },

    onlyInteger (event) {
      const numpadCodes = [96, 97, 98, 99, 100, 101, 102, 103, 104, 105]
      const codes = [8, 9, 37, 39, 46, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, ...numpadCodes] // numbers 0..9, arrows left/right, delete, backspace, tab
      if (codes.includes(event.keyCode)) {
        return
      }
      event.preventDefault()
    },
    setValue (event, inputType = 'default', inputKey, toggleNeeded = false) {
      const value = event.value
      if (toggleNeeded) {
        if (event.toggle_set !== null) {
          this.setNewSet(event.toggle_set)
        }
      }
      this.setActionProp({ ruleId: this.rule.id, key: inputKey, value })
      if (!this.ruleEdited) {
        this.setStateProp({ prop: 'ruleEdited', value: true })
      }
    },
    setActionsParams (event, key, toggleNeeded = false, dropdownValue = null) {
      let value
      const input = event.target
      switch (input.type) {
        case 'checkbox':
          value = input.checked
          break
        case 'number':
          value = parseFloat(input.value) || 0
          break
        case 'submit':
        case undefined:
          value = dropdownValue
          break
        default:
          value = input.value
      }
      // toggle set
      if (toggleNeeded) {
        const selectedOption = input.selectedIndex
        const setIndex = input[selectedOption].id
        if (input[selectedOption].id) {
          this.setNewSet(setIndex)
        }
      }
      this.setActionProp({ ruleId: this.rule.id, key, value })
      if (!this.ruleEdited) {
        this.setStateProp({ prop: 'ruleEdited', value: true })
      }
    },

    // set new properties on action change
    setNewSet (set: string|number = 0) {
      this.activeSet = set
      this.setRuleProp({
        id: this.rule.id,
        type: 'data',
        value: { set: parseInt(set as string) }
      })
      if (
        this.rule.execution_spec.key === 'ADD_NAME' &&
        String(this.rule.data.set) === String(1) &&
        this.rule.data.set !== set
      ) {
        this.setNestedProp({
          id: this.rule.id,
          field: 'execution_spec',
          prop: 'options',
          value: {
            type: 'expression',
            add_date: false,
            add_date_include_time: false,
            add_text: false,
            add_text_value: '',
            add_metric: false,
            add_metric_value: this.getActionSetInputByKey(this.action.sets[1], 'add_metric_value')?.default ?? 'cpc',
            add_metric_time_preset: this.getActionSetInputByKey(this.action.sets[1], 'add_metric_time_preset')?.default ?? 'today'
          }
        })
        return null
      }
      if (this.action.sets[set]) {
        this.action.sets[set].forEach(group =>
          group.inputs.forEach(input => {
            if (!this.toggles.includes(input.key)) {
              this.setActionProp({
                ruleId: this.rule.id,
                key: input.key,
                value:
                  this.options[input.key] || input.default
              })
            }
            // nested inputs (range)
            // if (input.inputs.length)
          })
        )
      }
    },
    // get dropdown name by value
    dropdownValueName (input, value) {
      let res
      input.values.every(group => {
        if (group.values[value]) {
          if (group.values[value].length > 40) {
            res = value.split('_').join(' ')
          } else {
            res = group.values[value]
          }
          return true
        }
      })
      return res
    }
  }
}
