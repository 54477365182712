<template>
  <div>
    <button class="trigger-group d-flex justify-content-start align-items-star pl-0 bold"
            @click.stop="expandDropdown">
      <div
        class="dropdown-result-icon mr-2"
        :class="{ expanded: isDropdownExpanded }"
      >
        <img src="../../../../assets/icons/chevron-icon.svg" alt="">
      </div>
      <span class="ps-1">{{ group.group }}</span>
    </button>
    <div
      class="mb-2"
      :style="{
        display:
          this.isDropdownExpanded ||
          (this.search_trigger && this.filteredTriggers.length)
            ? 'block'
            : 'none'
      }"
    >
      <div
        v-for="(trigger, index) in filteredTriggers"
        :key="index"
        class="select-item d-flex justify-content-between ps-4 pe-2"
      >
        <button
          class="item"
          :class="{
            chosen: isSelected(trigger.field),
            'none-clickable': isSelected(trigger.field)
          }"
          @click="selectMetric(trigger)"
        >
          <span>{{ trigger.name }}</span>
        </button>
        <button class="btn_svg px-2" @click="$emit('show-modal', trigger.id)">
          <img
            v-if="trigger.type === 'custom'"
            src="../../../../assets/icons/edit-icon.svg"
            alt="Edit custom metric"
            style="width: 15px"
          />
        </button>
        <button
          v-tooltip="{
            content: trigger.description,
            boundariesElement: trigger.description && trigger.description.length > 60 ? 'html' : undefined,
            placement: 'bottom',
            trigger: 'hover click'
          }"
          @click.stop
        >
          <img
            style="max-width: 15px"
            src="../../../../assets/icons/info-icon-gray.svg"
            alt=""
          >
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'TriggerGroup',
  props: {
    ruleId: {
      required: false
    },
    conditionGroup: {
      required: true,
      type: Object
    },
    group: {
      required: true,
      type: Object
    },
    search_trigger: {
      required: false
    },
    condition: {
      required: true,
      type: Object
    },
    metric_entity_type: {
      required: true,
      type: String
    }
  },
  data () {
    return {
      isDropdownExpanded: false
    }
  },

  computed: {
    ...mapGetters({
      metricAvailable: 'rulesMeta/metricAvailable',
      selectedMetrics: 'rulesMeta/selectedMetrics',
      currentService: 'adService/getService'
    }),

    filteredTriggers () {
      return this.group.fields.filter(
        field =>
          this.searchByName(field.name) &&
          this.metricAvailable({ trigger: field, metric_entity_type: this.metric_entity_type, service: this.currentService })
      )
    },

    isFirstCondition () {
      return Object.keys(this.conditionGroup.conditions).length === 1
    }
  },

  methods: {
    searchByName (field) {
      if (this.search_trigger) {
        return (
          field.toLowerCase().indexOf(this.search_trigger.toLowerCase()) !== -1
        )
      } else {
        return true
      }
    },

    expandDropdown () {
      this.isDropdownExpanded = !this.isDropdownExpanded
      if (this.isDropdownExpanded === true) {
        // $('[data-toggle="tooltip"]').tooltip()
      }
    },

    selectMetric (metric) {
      this.$emit('select-metric', metric)
    },

    isSelected (metric) {
      return this.selectedMetrics(this.ruleId).includes(metric)
    }
  }
})
</script>
<style lang="scss" scoped>
  .trigger-group {
    margin-bottom: 12px;
    width: 100%;
    span {
      text-align: left;
      font-weight: 600;
      font-size: 0.9em;
      color: #212529;
    }
  }
  .select-item {
    margin-bottom: 10px;
    .item {
      span {
        color: #212529;
      }
    }
  }
  .chosen {
    position: relative;
  }
  .chosen::before {
    position: absolute;
    content: "";
    top: 25%;
    left: 0;
    background-repeat: no-repeat;
    width: 12px;
    height: 12px;
  }
</style>
