<template>
  <td :class="{ clicked: clicked }" @click="$emit('cell_click', value)">
    <span v-if="clicked">{{ value }}</span>
  </td>
</template>

<script>
import Vue from 'vue'
export default Vue.extend({
  name: 'SchedulerCell',
  props: ['value', 'clicked']
})
</script>
