<template>
  <section class="position-relative border-right padding-x-3 dropdown dropdown-type-1">
    <button
      type="button"
      class="btn dropdown-toggle"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      {{ selected_values_title }}
    </button>

    <div class="dropdown-menu md p-3">
      <div class="dropdown-select-content m-0 h-100">
        <div
          v-for="(name, value) in field.values"
          :key="name"
          class="d-flex align-items-center justify-content-between mb-2"
        >
          <label class="light" :for="name" @click.stop> {{ name }} </label>
          <input
            v-model="selected_data"
            type="checkbox"
            :value="value"
            class="form-check-input"
            :id="name"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Vue from 'vue'
export default Vue.extend({
  name: 'DropdownMetricFields',
  props: ['field', 'rule', 'condition'],
  data () {
    return {
      selectedData: []
    }
  },
  computed: {
    selected_values_title () {
      let postfix = ''

      if (Array.isArray(this.condition.value)) {
        if (this.condition.value.length === 0) return 'Select values'
        if (this.condition.value.length >= 3) {
          postfix = ` and ${this.condition.value.length - 2} more`
        }
        return (
          this.condition.value
            .slice(0, 2)
            .map(v => this.field.values[v])
            .join(', ') + postfix
        )
      } else return 'Select values'
    },

    selected_data: {
      get () {
        return this.selectedData
      },
      set (value) {
        this.selectedData = value
        this.$store.dispatch('rules/setConditionProp', {
          ruleId: this.rule.id,
          itemId: this.condition.uid,
          type: 'value',
          value
        })
      }
    }
  },
  mounted () {
    this.selectedData = this.condition.value || []
  }
})
</script>

<style lang="scss" scoped>
section {
  > button {
    width: 100%;
    height: 100%;
    padding: 0 1rem;
  }
}
/*button:not(.visited) {*/
/*  color: #9ca1a6;*/
/*}*/
</style>
