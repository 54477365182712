var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('button',{staticClass:"trigger-group d-flex justify-content-start align-items-star pl-0 bold",on:{"click":function($event){$event.stopPropagation();return _vm.expandDropdown.apply(null, arguments)}}},[_c('div',{staticClass:"dropdown-result-icon mr-2",class:{ expanded: _vm.isDropdownExpanded }},[_c('img',{attrs:{"src":require("../../../../assets/icons/chevron-icon.svg"),"alt":""}})]),_c('span',{staticClass:"ps-1"},[_vm._v(_vm._s(_vm.group.group))])]),_c('div',{staticClass:"mb-2",style:({
      display:
        this.isDropdownExpanded ||
        (this.search_trigger && this.filteredTriggers.length)
          ? 'block'
          : 'none'
    })},_vm._l((_vm.filteredTriggers),function(trigger,index){return _c('div',{key:index,staticClass:"select-item d-flex justify-content-between ps-4 pe-2"},[_c('button',{staticClass:"item",class:{
          chosen: _vm.isSelected(trigger.field),
          'none-clickable': _vm.isSelected(trigger.field)
        },on:{"click":function($event){return _vm.selectMetric(trigger)}}},[_c('span',[_vm._v(_vm._s(trigger.name))])]),_c('button',{staticClass:"btn_svg px-2",on:{"click":function($event){return _vm.$emit('show-modal', trigger.id)}}},[(trigger.type === 'custom')?_c('img',{staticStyle:{"width":"15px"},attrs:{"src":require("../../../../assets/icons/edit-icon.svg"),"alt":"Edit custom metric"}}):_vm._e()]),_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: trigger.description,
          boundariesElement: trigger.description && trigger.description.length > 60 ? 'html' : undefined,
          placement: 'bottom',
          trigger: 'hover click'
        }),expression:"{\n          content: trigger.description,\n          boundariesElement: trigger.description && trigger.description.length > 60 ? 'html' : undefined,\n          placement: 'bottom',\n          trigger: 'hover click'\n        }"}],on:{"click":function($event){$event.stopPropagation();}}},[_c('img',{staticStyle:{"max-width":"15px"},attrs:{"src":require("../../../../assets/icons/info-icon-gray.svg"),"alt":""}})])])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }