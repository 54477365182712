















































































































import Vue from 'vue'
import ConditionItem from './ConditionItem.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway'
import { CONDITION_TYPE_CONDITION, CONDITION_TYPE_GROUP } from '@/constants/FbAutomatedRule'
import ConditionItemComplex from '@/components/strategies/Rule/TaskWrapper/ConditionItemComplex.vue'

export default Vue.extend({
  name: 'ConditionGroup',
  components: { ConditionItemComplex, ConditionItem },
  props: {
    rule: {
      required: true,
      type: Object
    },
    ruleId: {
      required: true
    },
    demoRule: {
      required: false,
      default: false,
      type: Boolean
    },
    group: {
      required: true,
      type: Object
    },
    level: {
      required: true,
      type: Number
    },
    operator: {
      required: true,
      type: String
    }
  },
  mixins: [clickaway],
  data () {
    return {
      switcherDropdown: false,
      conditions: this.group.conditions,
      groupId: this.group.uid,
      addDropdownMenuVisible: false
    }
  },

  mounted () {
    // enable tooltips onload
    // $('[data-toggle="tooltip"]').tooltip()
  },
  computed: {
    ...mapGetters('rulesMeta', ['selectedTrigger']),
    ...mapGetters(['get_width']),
    isSingleRule () {
      return !!Object.values(this.rule.conditions.conditions).filter(v => !!v['conditions']).length
    }
  },
  methods: {
    ...mapMutations('rules', [
      'addDefaultGroup',
      'setGroupProp'
    ]),
    ...mapActions('rules', [
      'addDefaultCondition'
    ]),
    conditionTypeCondition (condition) {
      if (condition.type !== CONDITION_TYPE_CONDITION) {
        return false
      }
      const tr = this.selectedTrigger(condition.metric)
      return tr?.is_complex !== true
    },
    conditionTypeConditionComplex (condition) {
      if (condition.type !== CONDITION_TYPE_CONDITION) {
        return false
      }
      const tr = this.selectedTrigger(condition.metric)
      return tr?.is_complex === true
    },
    conditionTypeGroup (condition) {
      return condition.type === CONDITION_TYPE_GROUP
    },
    addCondition () {
      if (!this.demoRule) {
        this.addDefaultCondition({
          ruleId: this.rule.id,
          groupId: this.groupId,
          level: this.level
        })
      }
    },
    addGroup () {
      if (this.level !== 4 && !this.demoRule) {
        this.addDefaultGroup({
          ruleId: this.rule.id,
          groupId: this.groupId,
          level: this.level
        })
      }
    },
    editGroupOperator (value) {
      this.setGroupProp({
        ruleId: this.rule.id,
        groupId: this.groupId,
        level: this.level,
        type: 'operator',
        value
      })
    }
  }
})
