
































































































































































































































































































































import Vue from 'vue'

import DropdownMetricFields from './DropdownMetricFields.vue'
import TimeRangeSection from './TimeRangeSection.vue'
import TriggerSection from './TriggerSection.vue'

import moment from 'moment'
import _ from 'lodash'
import { symbols } from '@/models/currencyCodes'
import DatePicker from 'vue2-datepicker'
import VSelect from '@/components/BaseComponents/VSelect/VSelect.vue'
import { mapState, mapMutations, mapGetters } from 'vuex'
import 'vue2-datepicker/index.css'
import {
  METRIC_TYPE_SIMPLE,
  METRIC_TYPE_CUSTOM,
  OPERATORS_TITLE,
  OPERATOR_IS_WITHIN,
  OPERATOR_IS_NOT_WITHIN,
  COMPARE_WITH_VALUE,
  COMPARE_WITH_RANKING,
  COMPARE_WITH_OPERATORS,
  COMPARE_WITH_TOP,
  COMPARE_WITH_VALUES,
  METRIC_TYPE_COMPLEX,
  METRIC_OPERATORS
} from '@/constants/FbAutomatedRule'

export default Vue.extend({
  name: 'ConditionItem',
  props: {
    rule: {
      required: true,
      type: Object
    },
    ruleId: {
      required: true
    },
    condition: {
      required: true,
      type: Object
    },
    conditionGroup: {
      required: true,
      type: Object
    },
    demoRule: {
      required: false,
      default: false,
      type: Boolean
    },
    groupId: {
      required: true
    },
    level: {
      required: true,
      type: Number
    }
  },
  components: {
    DatePicker,
    TriggerSection,
    TimeRangeSection,
    DropdownMetricFields,
    VSelect
  },
  data () {
    return {
      itemId: this.condition.uid,
      operators: OPERATORS_TITLE,
      search_trigger: null,
      compare_with: null,
      compare_with_value_number: 0,
      compare_with_ranking_type: COMPARE_WITH_TOP,
      simple_value_backup: null
    }
  },
  mounted () {
    if (this.condition?.operator === OPERATOR_IS_WITHIN || this.condition?.operator === OPERATOR_IS_NOT_WITHIN) {
      this.compare_with = COMPARE_WITH_RANKING
      this.compare_with_value_number = this.condition.value.value
      this.compare_with_ranking_type = this.condition.value.ranking_type
    } else {
      this.simple_value_backup = this.condition?.value
      this.compare_with = COMPARE_WITH_VALUE
    }
    if (this.demoRule) {
      return
    }
    this.applyWatchers()
  },
  computed: {
    ...mapState({
      errors: state => (state as any).rules.errors,
      triggerBasedRules: state => (state as any).rulesMeta.triggerBasedRules,
      ruleEdited: state => (state as any).rules.ruleEdited
    }),
    compare_with_values_list () {
      const arr = []
      for (const val in this.compare_with_values) {
        arr.push({
          label: this.compare_with_values[val],
          value: val
        })
      }
      return arr
    },
    suitableOperatorsList () {
      const arr = []
      for (const operator in this.suitableOperators) {
        arr.push({
          label: operator,
          value: this.suitableOperators[operator]
        })
      }
      return arr
      // return this.suitableOperators
    },
    ...mapGetters({
      selectedCustomMetric: 'rulesMeta/selectedCustomMetric',
      selectedTrigger: 'rulesMeta/selectedTrigger',
      adAccountById: 'adAccounts/getById',
      allAdAccounts: 'adAccounts/getAllAccounts',
      getError: 'rules/getError',
      getErrorByConditionId: 'rules/getErrorByConditionId',
      getConditionItemError: 'rules/getConditionItemError'
    }),

    compare_with_value_option () {
      return COMPARE_WITH_VALUE
    },
    compare_with_ranking_option () {
      return COMPARE_WITH_RANKING
    },
    compare_with_values () {
      return COMPARE_WITH_VALUES
    },
    ranking_type_title () {
      return this.compare_with_values[this.compare_with_ranking_type]
    },
    compare_with_value () {
      return {
        value: this.compare_with_value_number,
        ranking_type: this.compare_with_ranking_type
      }
    },

    levelCond () {
      return 'conditions.conditions'
    },

    hasErrors () {
      return Object.keys(this.errors).length
    },

    currency () {
      let adAccount = this.allAdAccounts[0]
      if (this.rule.ad_account_id) {
        adAccount = this.adAccountById(this.rule.ad_account_id)
      }

      if (this.condition.metric_type === METRIC_TYPE_CUSTOM) {
        const metric = this.selectedCustomMetric(this.condition.custom_metric_id)
        if (metric) {
          switch (metric.format) {
            case 'MONEY':
              if (adAccount) {
                return symbols.find(
                  c =>
                    c.abbreviation.toLowerCase() ===
                    adAccount.currency.toLowerCase()
                )?.symbol || '$'
              } else {
                return '$'
              }
            case 'PERCENTAGE':
              return '%'
            default:
              return null
          }
        }
      }

      if (this.valueType === 'percent') return '%'
      if (this.valueType === 'money') {
        if (!adAccount || !adAccount.currency) return '$'
        return symbols.find(
          c =>
            c.abbreviation.toLowerCase() === adAccount.currency.toLowerCase()
        ).symbol
      }
      return null
    },

    suitableOperators () {
      if (this.compare_with === COMPARE_WITH_RANKING) {
        return COMPARE_WITH_OPERATORS
      }
      if (this.selectedMetric && this.selectedMetric.operators) {
        return this.selectedMetric.operators
      } else if (!this.valueType || this.valueType === 'double') {
        return this.operators.numeric
      } else {
        return this.operators[this.valueType]
      }
    },

    selectedMetricKey: {
      get () {
        return this.condition.metric
      },
      set (v) {
        this.saveConditionProp('metric', v)
        this.saveConditionProp('metric_type', METRIC_TYPE_SIMPLE)
        // if (this.hasErrors) this.setErrors({})
      }
    },

    operator: {
      get () {
        return this.condition.operator
      },
      set (v) {
        this.saveConditionProp('operator', v)
      }
    },

    selectedMetric () {
      return this.$store.getters['rulesMeta/selectedTrigger'](
        this.selectedMetricKey
      )
    },

    firstValue: {
      get () {
        let v = 0
        if (Array.isArray(this.condition.value)) {
          v = this.condition.value[0] || 0
        } else {
          v = this.condition.value || 0
        }

        if (this.valueType === 'double' || this.valueType === 'percent') {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          v = parseFloat(v.toString()).toFixed(2)
        } else {
          v = parseFloat(v.toString())
        }
        return isNaN(v) ? 0 : v
      },
      set (v: any) {
        let res = []

        if (v && this.valueType !== 'double' && this.valueType !== 'percent') {
          res = [
            parseFloat(v.replace(/[^\d]/, '')),
            parseFloat(this.secondValue)
          ]
        } else if (
          v &&
          (this.valueType === 'double' || this.valueType === 'percent')
        ) {
          res = [
            parseFloat(v).toFixed(2),
            parseFloat(this.secondValue).toFixed(2)
          ]
        } else {
          res = [0, parseFloat(this.secondValue)]
        }

        if (this.operator === 'IN_RANGE' || this.operator === 'NOT_IN_RANGE') {
          this.saveConditionProp('value', res)
          this.simple_value_backup = res
          // if (this.hasErrors) this.setErrors({})
        }
      }
    },

    secondValue: {
      get () {
        let v = 0
        if (Array.isArray(this.condition.value)) {
          v = this.condition.value[1] || 0
        } else {
          v = this.condition.value || 0
        }

        if (this.valueType === 'double' || this.valueType === 'percent') {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          v = parseFloat(v.toString()).toFixed(2)
        } else {
          v = parseFloat(v.toString())
        }
        return isNaN(v) ? 0 : v
      },
      set (v: any) {
        let value = []

        if (v && this.valueType !== 'double' && this.valueType !== 'percent' && this.valueType !== 'money') {
          v = parseFloat(v.replace(/[^\d]/, ''))
        } else if (
          v &&
          (this.valueType === 'double' || this.valueType === 'percent' || this.valueType === 'money')
        ) {
          v = parseFloat(v).toFixed(2)
        } else {
          v = 0
        }

        if (this.operator === 'IN_RANGE' || this.operator === 'NOT_IN_RANGE') {
          value = [parseFloat(this.firstValue), v]
        } else {
          value = v
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (isNaN(value) && !Array.isArray(value)) value = 0

        if (this.valueType !== 'datetime') {
          this.saveConditionProp('value', value)
          this.simple_value_backup = value
          // if (this.hasErrors) this.setErrors({})
          this.$forceUpdate()
        }
      }
    },

    string_value: {
      get () {
        return this.condition.value || ''
      },
      set (v) {
        this.saveConditionProp('value', v)
        this.simple_value_backup = v
      }
    },

    firstDate: {
      get () {
        if (Array.isArray(this.condition.value)) {
          return (
            moment.unix(this.condition.value[0]).valueOf() || moment().valueOf()
          )
        }
        return moment.unix(this.condition.value).valueOf() || moment().valueOf()
      },
      set (v) {
        let res = []
        if (!v) {
          res = [
            moment(this.secondDate).unix(),
            moment().unix()
          ]
        } else {
          res = [
            moment(v).unix(),
            moment(this.secondDate).unix()
          ]
        }
        this.saveConditionProp('value', res)
        this.simple_value_backup = res
      }
    },

    secondDate: {
      get () {
        if (!this.condition.value) {
          return moment().valueOf()
        } else if (Array.isArray(this.condition.value)) {
          return (
            moment.unix(this.condition.value[1]).valueOf() || moment().valueOf()
          )
        }
        return moment.unix(this.condition.value).valueOf() || moment().valueOf()
      },
      set (v) {
        let res = null
        if (!v) {
          res = moment().unix()
        } else {
          switch (this.operator) {
            case 'IN_RANGE':
            case 'NOT_IN_RANGE':
              res = [this.condition.value[0], moment(v).unix()]
              break
            default:
              res = moment(v).unix()
          }
        }
        this.saveConditionProp('value', res)
        this.simple_value_backup = res
      }
    },

    operatorTitle () {
      if (this.operator) {
        return this.suitableOperators[this.operator]
      } else {
        return ''
      }
    },

    valueType () {
      return this.selectedMetric && this.selectedMetric.type
        ? this.selectedMetric.type
        : 'numeric'
    },

    time_frame_available () {
      if (this.selectedMetric) {
        return this.selectedMetric.time_range
      } else {
        return true
      }
    },
    compare_with_enabled () {
      return this.selectedTrigger(this.condition.metric)?.is_ranking
    }
  },

  methods: {
    applyWatchers () {
      this.$watch('firstValue', function () {
        this.removeErr({ key: this.condition.id, field: 'value' })
      })
      this.$watch('secondValue', function () {
        this.removeErr({ key: this.condition.id, field: 'value' })
      })
      this.$watch('string_value', function () {
        this.removeErr({ key: this.condition.id, field: 'value' })
      })
      this.$watch('compare_with', function (v) {
        this.saveConditionProp('operator', Object.keys(this.suitableOperators)[0])
        if (v === this.compare_with_ranking_option) {
          this.simple_value_backup = this.condition.value
          this.saveConditionProp('value', this.compare_with_value)
        } else {
          // TODO: очень плохой код, это выпилить при рефакторинге компонента с разделением на отдельные компоненты
          this.saveConditionProp('value', this.simple_value_backup)
        }
      })
      this.$watch('compare_with_value_number', function () {
        this.saveConditionProp('value', this.compare_with_value)
      })
      this.$watch('compare_with_ranking_type', function () {
        this.saveConditionProp('value', this.compare_with_value)
      })
    },
    isPositive (ev) {
      if (!this.ruleEdited) {
        this.setStateProp({
          prop: 'ruleEdited',
          value: true
        })
      }
      if (ev.key === '-') ev.preventDefault()
    },
    ...mapMutations('rules', ['setErrors', 'setStateProp', 'removeErr']),

    deleteCondition () {
      this.removeErr({ key: this.condition.id, field: '' })
      this.$store.commit('rules/deleteCondition', {
        ruleId: this.rule.id,
        groupId: this.groupId,
        itemId: this.itemId,
        level: this.level
      })
      if (!Object.keys(this.rule.conditions.conditions).length) {
        this.$store.commit('rules/addDefaultGroup', {
          ruleId: this.rule.id,
          level: 0
        })
      }
    },

    selectMetric (metric) {
      this.removeErr({ key: this.condition.id, field: '' })
      if (metric.type === 'datetime') {
        this.operator === 'IN_RANGE' || this.operator === 'NOT_IN_RANGE'
          ? (this.firstDate = null)
          : (this.secondDate = null)
      } else if (metric.type === 'dropdown') {
        this.saveConditionProp('value', [])
        this.simple_value_backup = []
      } else if (metric.type === 'string') {
        this.string_value = null
      } else {
        this.firstValue = 0
        this.secondValue = 0
      }

      // update operator if previous operator not available
      if (!this.suitableOperators[this.operator]) {
        this.operator = _.keys(this.suitableOperators)[0]
      }
      if (
        this.triggerBasedRules.includes(metric.field) &&
        this.operator === 'EQUAL'
      ) {
        this.setOperator(_.keys(this.suitableOperators)[0])
      }
      if (metric.is_complex) {
        this.saveConditionProp('metric_type', METRIC_TYPE_COMPLEX)
        if (METRIC_OPERATORS[metric.field]) {
          this.saveConditionProp('operator', METRIC_OPERATORS[metric.field])
        }
      }
    },

    setTimeRange (time) {
      this.saveConditionProp('time_range', time)
    },

    setCompareWithValue (val) {
      this.compare_with_ranking_type = val.value
    },

    setOperator (op) {
      this.operator = op
      // if (this.selectedMetric && (op === 'IN_RANGE' || op === 'NOT_IN_RANGE'))
      //   this.selectedMetric.type === 'datetime'
      //     ? (this.firstDate = null)
      //     : (this.firstValue = null)
      if (!this.selectedMetric) {
        return
      }
      if (op === 'IN_RANGE' || op === 'NOT_IN_RANGE') {
        this.selectedMetric.type === 'datetime'
          ? (this.firstDate = null)
          : (this.firstValue = null)
      } else {
        if (this.selectedMetric.type === 'datetime') {
          const res = moment(this.secondDate).unix()
          this.saveConditionProp('value', res)
          this.simple_value_backup = res
        } else {
          this.firstValue = null
        }
      }
    },
    // search error by id
    errorByType (type) {
      let key

      if (!this.errors) return null
      Object.keys(this.errors).forEach(id => {
        if (id.includes(`${this.itemId}.${type}`)) key = id
      })
      return this.errors[key] ? this.errors[key][0] : null
    },
    saveConditionProp (type, value) {
      this.$store.dispatch('rules/setConditionProp', {
        ruleId: this.ruleId,
        itemId: this.itemId,
        type: type,
        value: value
      })
    },
    set_compare_with_ranking_type (v) {
      this.compare_with_ranking_type = v
    }
  }
})
