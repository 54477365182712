<template>
  <div class="td-content">
    <div class="d-flex" style="gap: 4px">
      <button :disabled="!item.tag_snippets" class="btn btn-sm btn-custom" @click="$emit('tagSetupModal',item.id)">
        <img :src="require('@/assets/icons/html.svg')" alt="">
      </button>
      <button class="btn btn-sm btn-custom" @click="$emit('updateModal',item.id)">
        <img :src="require('@/assets/icons/edit-icon.svg')" alt="">
      </button>
      <button class="btn btn-sm btn-custom" :disabled="item.status === 'REMOVED' || disableBtn" @click="emitAndDisableBtn(item.id)">
        <img :src="require('@/assets/icons/delete-icon.svg')" alt="">
      </button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
export default Vue.extend({
  name: 'ConversionTableAction',
  props: {
    item: {
      type: Object
    }
  },
  data () {
    return {
      disableBtn: false
    }
  },
  methods: {
    emitAndDisableBtn (id) {
      this.$emit('deleteConversion', id)
      this.disableBtn = true
    }
  }
})
</script>

<style scoped>

</style>
