<template>
  <div class="task-wrapper margin-top-2 mb-3">
    <div class="form-group w-100 expression-task-select">
      <label for="type" class="margin-bottom-2">
        <span class="text-small text-bold">Item</span>
      </label>
      <div>
        <VSelect
          :min-width="5"
          :options="opt"
          :value="opt[1]"
          @input="setActionsParamsName($event)">
          <template #selected-option="{ selected }">
            <p class="text-small" style="width: 100%;overflow: hidden; text-overflow: ellipsis"> {{selected.label}}</p>
          </template>
          <template #option="{ option }">
            <p class="text-small">{{ option.label }}</p>
          </template>
        </VSelect>
<!--        <select-->
<!--          id="type"-->
<!--          class="form-control test"-->
<!--          @change="setActionsParams($event, 'type', true)"-->
<!--        >-->
<!--          <option id="0" value="text">Text</option>-->
<!--          <option id="1" value="expression" selected>Expression</option>-->
<!--        </select>-->
        <!-- <small data-v-1c4176be="" class="text-red"> </small> -->
      </div>
    </div>
    <!-- add date group -->
    <div class="line">
      <div class="custom-switch-border position-relative">
        <label for="date">
          <span class="text-small">Date</span>
        </label>
        <Switcher id="date"
                  @toggleSwitcher="setActionsParams($event.event, date.input.key)"
                  :checked="options.add_date || false" :name="'date'"
        />
      </div>

      <div v-if="options.add_date" class="custom-switch-border">
          <label for="time">
            <span class="text-small">Include time</span>
          </label>
          <input
            type="checkbox"
            class="ml-3"
            id="time"
            :checked="options.add_date_include_time || false"
            @change="setActionsParams($event, date.subinput.key)"
          />
      </div>
    </div>

    <!-- add text group -->
    <div class="line">
      <div class="custom-switch-border custom-switch-input position-relative ml-3">
        <label for="text">
          <span class="text-small">Text</span>
        </label>
        <Switcher id="text"
                  @toggleSwitcher="setActionsParams($event.event, text.input.key)"
                  :checked="options.add_text || false" :name="'text'"
        />
      </div>

      <div v-if="options.add_text" class="custom-switch d-flex align-items-center">
        <input
          type="text"
          class="form-control input-type-1"
          placeholder="Add text"
          :value="options.add_text_value || text.subinput.default"
          @change="setActionsParams($event, text.subinput.key)"
        />
      </div>
    </div>
<!--    <error-msg-->
<!--      class="pb-2 ml-4"-->
<!--      :errors="-->
<!--        $store.getters['fb_automated_rules/errorMsg'](-->
<!--          `execution_spec.options.${text.subinput.key}`)"-->
<!--    />-->
    <!-- add metric group -->
    <div class="line">
      <div class="custom-switch-border position-relative ml-3">
          <label for="metric">
            <span class="span-small">Metric value</span>
          </label>
          <Switcher id="metric"
                    :name="'metric'"
                    @toggleSwitcher="setActionsParams($event.event, metric.input.key)"
                    :checked="options.add_metric || false"
          />
      </div>

      <div class="custom-switch" v-if="options.add_metric">
        <VSelect :options="subInputMetric"
                 :min-width="250"
                 :value="subInputMetric.find(v => v.value === options.add_metric_value)"
                 @input="setActionsParamsName($event, 'default', 'add_metric_value', $event.value, false)"
        >
          <template #selected-option="{ selected }">
            <p class="text-small" style="width: 100%;overflow: hidden; text-overflow: ellipsis"> {{selected.label}}</p>
          </template>
          <template #option="{ option }">
            <p class="text-small" style="width: 100%;text-overflow: ellipsis;overflow: hidden;">{{ option.label }}</p>
          </template>
        </VSelect>
<!--        <select-->
<!--          class="form-control"-->
<!--          @change="setActionsParams($event, metric.subinput[0].key)"-->
<!--        >-->
<!--          <template v-for="(value, key) in metric.subinput[0].values">-->
<!--            <option-->
<!--              :key="key"-->
<!--              :value="key"-->
<!--              :selected="String(key) === String(options.add_metric_value)"-->
<!--            >-->
<!--              {{ value.value }}-->
<!--            </option>-->
<!--          </template>-->
<!--        </select>-->
      </div>
      <div
        v-if="options.add_metric"
        class="position-relative custom-switch"
      >
        <VSelect :options="subInputListLifeTime"
                 :min-width="250"
                 :value="subInputListLifeTime.find(v => v.value === options.add_metric_time_preset)"
                 @input="setActionsParamsName($event, 'default', 'add_metric_time_preset', $event.value, false)">
          <template #selected-option="{ selected }">
            <p class="text-small" style="width: 100%;overflow: hidden; text-overflow: ellipsis"> {{selected.label}}</p>
          </template>
          <template #option="{ option }">
            <p class="text-small" style="width: 100%;text-overflow: ellipsis;overflow: hidden;">{{ option.label }}</p>
          </template>
        </VSelect>
<!--        <button-->
<!--          class="d-flex align-items-start justify-content-between"-->
<!--          style="width: 360px;"-->
<!--          id="selectTime"-->
<!--          data-bs-toggle="dropdown"-->
<!--          aria-expanded="false"-->
<!--        >-->
<!--          <span style="font-size: 0.9em">-->
<!--            {{dropdownValueName(metric.subinput[1], options.add_metric_time_preset || metric.subinput[1].default)}}-->
<!--          </span>-->
<!--          <img src="../../../../assets/icons/chevron-icon.svg" alt="">-->
<!--        </button>-->

<!--        <div class="dropdown-menu py-3p pl-3 pr-2">-->
<!--          <div class="dropdown-select-content m-0 h-100">-->
<!--            <button-->
<!--              v-for="(value, key) in metric.subinput[1].values"-->
<!--              :key="key"-->
<!--              class="item mb-0"-->
<!--              :class="{ chosen: options.add_metric_time_preset === key }"-->
<!--              @click="setActionsParams($event, metric.subinput[1].key, false, key)"-->
<!--            >-->
<!--              {{ value.value }}-->
<!--              <div class="dropdown-subitem-icon"></div>-->
<!--            </button>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
    <div class="line">
      <span class="text-muted"> Preview:&nbsp;{{ previewMessage }} </span>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

import Switcher from '../../../../components/Switcher.vue'
import VSelect from '@/components/BaseComponents/VSelect/VSelect'
import NewUiSwitcher from '@/components/NewUIComponents/NewUiSwitcher'

import { mapMutations } from 'vuex'

export default {
  name: 'ExpressionTask',
  props: ['action', 'options'],
  components: {
    Switcher,
    VSelect
    // NewUiSwitcher
  },
  data () {
    return {
      opt: [
        {
          id: 0,
          label: 'Text',
          value: 'text',
          toggle_set: 0
        },
        {
          id: 1,
          label: 'Expression',
          value: 'expression',
          toggle_set: 1
        }
      ]
    }
  },
  mounted () {
    // временный костыль - сетим дефолтное значение для поля add_text_value
    let add_text_value_input_object = null
    this.action.sets[1].some(object => {
      add_text_value_input_object = object.inputs.find(input => input.key === 'add_text_value')
      return !!add_text_value_input_object
    })
    add_text_value_input_object && this.setActionsParams({
      target: {
        type: 'text',
        value: add_text_value_input_object.default
      }
    }, 'add_text_value')
  },
  computed: {
    subInputMetric () {
      const arr = []
      Object.keys(this.metric.subinput[0].values).forEach((v, key) => {
        arr.push({
          key: key,
          value: v,
          label: this.metric.subinput[0].values[v].value
        })
      })
      return arr
    },
    subInputListLifeTime () {
      const arr = []
      Object.keys(this.metric.subinput[1].values).forEach((v, key) => {
        arr.push({
          key: key,
          value: v,
          label: this.metric.subinput[1].values[v].value
        })
      })
      return arr
    },
    date () {
      return {
        input: this.action.sets[1][1].inputs[0],
        subinput: this.action.sets[1][2].inputs[0]
      }
    },
    metric () {
      return {
        input: this.action.sets[1][5].inputs[0],
        subinput: [
          this.action.sets[1][6].inputs[0],
          this.action.sets[1][7].inputs[0]
        ]
      }
    },
    text () {
      return {
        input: this.action.sets[1][3].inputs[0],
        subinput: this.action.sets[1][4].inputs[0]
      }
    },
    dateValue () {
      return this.options.add_date
    },

    textValue () {
      return this.options.add_text
    },

    metricValue () {
      return this.options.add_metric
    },

    previewMessage () {
      let res = ''
      if (!this.dateValue && !this.textValue && !this.metricValue) {
        return 'nothing will be added'
      }
      if (this.dateValue) res += '2020-12-12 '
      if (this.options.add_date_include_time) res += '00:00 '
      if (this.textValue) res += this.options.add_text_value || ''
      if (this.metricValue) res += ' 3.0'
      return res
    }
  },

  methods: {
    setActionsParams (event, key, toggleNeeded = false, dropdownKey = null) {
      this.$emit('setActionsParams', event, key, toggleNeeded, dropdownKey)
      if (dropdownKey) Vue.set(this.metric.subinput[1], 'value', dropdownKey)
    },
    setActionsParamsName (event, inputType, inputKey = 'type', dropdownKey = null, toggle_needed = true) {
      this.$emit('setActionsParamsName', {
        event: event,
        input_type: inputType,
        toggle_needed: toggle_needed,
        value: event.value,
        input_key: inputKey
      })
      if (dropdownKey) Vue.set(this.metric.subinput[1], 'value', dropdownKey)
    },
    // get dropdown name by value
    dropdownValueName (input, value) {
      return input.values[value].value
    }
  }
}
</script>

<style lang="scss" scoped>
  .expression-task-select {
    width: 100%;
    max-width: 320px;
    padding: 0 0 10px 0;
    margin: 0 10px;
    @media (max-width: 690px) {
      max-width: 100%;
      margin: 0;
    }
  }
  .line {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 5px 0;
    flex-wrap: wrap;
    @media (max-width: 1366px) {
      flex-wrap: wrap;
    }
    .custom-switch-border {
      background: #fff;
      min-width: 250px;
      max-width: 320px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 10px 0 10px 10px;
      border: 1px solid #CED4DA;
      border-radius: 4px;
      height: 36px;
      padding: 0 10px;
      width: 100%;
      &:not(:first-child) {
        margin-left: 10px;
      }
      @media (max-width: 690px) {
        max-width: 100%;
        margin-left: 0;
        &:not(:first-child) {
          margin-left: 0;
        }
      }
      select {
        width: 100%;
      }
    }
    .custom-switch {
      max-width: 320px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 10px 0 10px 10px;
      border: none;
      @media (max-width: 690px) {
        max-width: 100%;
        margin: 10px 0;
      }
      select {
        width: 100%;
        height: 38px;
        border: 1px solid #D2D6DE
      }
    }
    .text-muted {
      color: $subtitle-middle;
      margin: 0 10px;
    }
  }
  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: auto;
    & > label {
      width: 42px;
    }
  }

  .dropdown-menu {
    min-width: 200px;
    .item {
      border-color: transparent;
      outline: none;
    }
  }
</style>
