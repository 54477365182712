import { render, staticRenderFns } from "./TriggerSection.vue?vue&type=template&id=20a29b1f&scoped=true&"
import script from "./TriggerSection.vue?vue&type=script&lang=ts&"
export * from "./TriggerSection.vue?vue&type=script&lang=ts&"
import style0 from "./TriggerSection.vue?vue&type=style&index=0&id=20a29b1f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20a29b1f",
  null
  
)

export default component.exports