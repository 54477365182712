










































import Vue from 'vue'

import TreeSelect from '@/components/BaseComponents/TreeSelect/TreeSelect.vue'
import RuleName from '@/components/strategies/Rule/TaskWrapper/RuleName.vue'
import RuleAction from '@/components/strategies/Rule/TaskWrapper/RuleAction.vue'
import ConditionGroup from '@/components/strategies/Rule/TaskWrapper/ConditionGroup.vue'
import { GOOGLE_SERVICE, LINKEDIN_SERVICE, SNAPCHAT_SERVICE } from '@/constants/FbAutomatedRule'
import _ from 'lodash'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default Vue.extend({
  name: 'TaskWrapper',
  components: {
    ConditionGroup,
    RuleName,
    RuleAction,
    TreeSelect
  },
  data () {
    return {
      searchValue: '',
      testOpen: true,
      test: '',
      preventSlackDropdownClosing: false,
      search_task: null,
      toggleActionGroups: {}
    }
  },
  props: {
    rule: {
      type: Object,
      required: true
    },
    demoRule: {
      type: Boolean,
      default: false
    }
  },
  created () {
    this.loadTaskActions()
  },
  computed: {
    ...mapState('rules', ['serverData']),
    ...mapState({
      taskActions: state => (state as any).rulesMeta.taskActions,
      ruleEdited: state => (state as any).rules.ruleEdited
    }),
    ...mapGetters({
      taskGroupAvailable: 'rules/taskGroupAvailable',
      getTaskByFrontKey: 'rulesMeta/getTaskByFrontKey',
      availableTaskFields: 'rules/availableTaskFields',
      getEntitiesBudgetInfo: 'rules/getEntitiesBudgetInfo',
      googleCampaignsIsSharedBudget: 'rules/googleCampaignsIsSharedBudget',
      currentService: 'adService/getService'
    }),
    ruleExecutionSpecKey () {
      return this.rule.execution_spec.key
    },
    slackDropDownShouldOpen () {
      return ({ noDrop, open, mutableLoading }) => {
        return this.preventSlackDropdownClosing || (noDrop ? false : open && !mutableLoading)
      }
    },
    entities () {
      return this.rule.entities
    },
    filtered_tasks_list_demo () {
      const arr = []
      if (this.taskActions && this.taskActions.length > 0) {
        this.taskActions.forEach(v => {
          const obj: any = {
            id: v.id,
            children: v.actions
          }
          const childrenArr = []
          v.actions.forEach(t => {
            childrenArr.push({
              name: t.name,
              label: t.name,
              key: t.key,
              id: t.key
            })
          })
          obj.label = v.group
          obj.children = childrenArr
          arr.push(obj)
        })
      }
      return arr
    },
    filtered_tasks_list () {
      const arr = []
      if (this.taskActions && this.taskActions.length > 0) {
        this.taskActions.forEach(v => {
          const obj: any = {
            id: v.id,
            children: v.actions
          }
          if (v.group) {
            const childrenArr = []
            v.actions.forEach(t => {
              if (this.searchValue.length > 0) {
                if (t.name.toLowerCase().includes(this.searchValue.toLowerCase())) {
                  if (t.available.includes(this.rule.entity_type.toLowerCase())) {
                    childrenArr.push({
                      name: t.name,
                      label: t.name,
                      key: t.key,
                      id: t.key
                    })
                  }
                }
              } else {
                if (t.available.includes(this.rule.entity_type.toLowerCase())) {
                  childrenArr.push({
                    name: t.name,
                    label: t.name,
                    key: t.key,
                    id: t.key
                  })
                }
              }
            })
            obj.label = v.group
            obj.children = childrenArr
            arr.push(obj)
          } else {
            const emptyArr = []
            v.actions.forEach(v => {
              if (v.available.includes(this.rule.entity_type.toLowerCase())) {
                emptyArr.push({
                  name: v.name,
                  label: v.name,
                  key: v.key,
                  id: v.key
                })
              }
            })
            emptyArr.forEach(t => {
              if (this.searchValue.length > 0) {
                if (t.name.toLowerCase().includes(this.searchValue.toLowerCase())) {
                  arr.unshift(t)
                }
              } else {
                arr.unshift(t)
              }
            })
          }
        })
      }
      return arr.filter(v => {
        if (v.children) {
          return v.children.length > 0
        } else {
          return v
        }
      })
    },

    taskAction: {
      get () {
        if (this.rule.execution_spec.key) {
          return this.getTaskByFrontKey(this.rule.execution_spec.key)
        } else {
          return this.taskActions[0].actions[0]
        }
      },
      set (action: Record<any, any> | null) {
        if (!action) return null
        const options = {}

        // set default from config
        if (!!action && action.sets[0]) {
          action.sets[0].forEach(group =>
            group.inputs.forEach(input => {
              if (input.type === 'range') {
                options[input.inputs[0].key] = input.inputs[0].default
                options[input.inputs[1].key] = input.inputs[1].default
              } else options[input.key] = input.default
            })
          )
        }

        this.$store.commit('rules/setRuleProp', {
          id: this.rule.id,
          type: 'execution_spec',
          value: {
            ...action,
            options: this.ruleEdited
              ? options
              : this.rule.execution_spec.options
          },
          ruleNotEdited: true
        })
      }
    },
    campaigns_budget_warning () {
      if (this.currentService === GOOGLE_SERVICE) {
        return this.googleCampaignsIsSharedBudget(this.rule.id) ? 'Please keep in mind that this campaign is using a shared budget.' : 'Please keep in mind that this campaign is using an individual budget.'
      }
      return this.getEntitiesBudgetInfo(this.rule.id, 'campaigns') ? 'Please keep in mind that this campaign is using Ad set budget.' : ''
    }
  },
  methods: {
    ...mapMutations('rules', ['setStateProp']),
    ...mapActions('rulesMeta', ['loadTaskActions']),
    setAction (key) {
      this.taskActions.forEach(v => {
        if (v?.key === key) {
          this.setTaskAction(v)
        }
        v.actions.forEach(t => {
          if (t.key === key) {
            this.setTaskAction(t)
          }
        })
      })
    },
    slackInputSearchPointerDownHandler (e: PointerEvent): void {
      const target = e.target as HTMLInputElement | undefined

      if (target) {
        target.focus()
      }
    },
    setTaskAction (value) {
      if (!this.ruleEdited) {
        this.setStateProp({ prop: 'ruleEdited', value: true })
      }
      this.taskAction = value
    },

    filteredTaskActions (taskGroup) {
      if (this.search_task) {
        return _.filter(this.availableTaskFields(this.rule.id, taskGroup.id), f =>
          f.name.toLowerCase().includes(this.search_task.toLowerCase())
        )
      } else {
        return this.availableTaskFields(this.rule.id, taskGroup.id)
      }
    },

    checkCampaignsBudget () {
      if (
        this.demoRule ||
        this.currentService === LINKEDIN_SERVICE || // for linkedin don't need to check this warnings
        this.currentService === SNAPCHAT_SERVICE ||
        !this.rule.execution_spec.key ||
        !this.rule.execution_spec.key.toLowerCase().includes('budget') ||
        !this.$store.state.campaigns.loaded
      ) {
        return false
      }
      return true
    },

    checkAdsetsBudget () {
      if (
        this.demoRule ||
        this.currentService === LINKEDIN_SERVICE ||
        this.currentService === SNAPCHAT_SERVICE ||
        !this.rule.execution_spec.key ||
        !this.rule.execution_spec.key.toLowerCase().includes('budget') ||
        !this.$store.state.campaigns.loaded
      ) {
        return false
      }
      return this.getEntitiesBudgetInfo(this.rule.id, 'ad_sets')
    }
  }
})
