<template>
  <div class="list_item py-4">
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <h2 class="h2-type-22">{{title}}</h2>
        <p class="p-gray">{{label}}</p>
      </div>
      <button class="btn btn-custom btn_list" @click="closed = !closed">
        <img alt="open/close" :src="require('@/assets/icons/arrow-variations.svg')" style="transform: rotate(270deg)"/>
      </button>
    </div>
    <new-ui-accordion :collapsed="closed">
      <slot name="body"/>
    </new-ui-accordion>
  </div>
</template>

<script>
import NewUiAccordion from '@/components/NewUIComponents/NewUiAccordion'
import Vue from 'vue'

export default Vue.extend({
  props: {
    title: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      closed: true
    }
  },
  components: {
    NewUiAccordion
  },
  name: 'ConversionOptions'
})
</script>

<style scoped>

</style>
