var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"conditions_group_wrapper",style:({'overflow-x': (_vm.level === 1 && _vm.get_width < 690 && _vm.isSingleRule) ? 'auto' : 'visible'})},[(Object.keys(_vm.group.conditions).length > 0)?_c('div',{staticClass:"conditions_group d-flex align-items-stretch",class:{'first-condition-group': _vm.level === 1}},[(_vm.level !== 1)?_c('div',{staticClass:"connecter"}):_vm._e(),_c('div',{class:['add_condition_button', 'level',
  {'or-style': _vm.operator === 'OR'},
  {'and-style': _vm.operator === 'AND'}]},[(Object.keys(_vm.group.conditions).length > 1)?_c('button',{staticClass:"control"},[_c('span',{class:{ inactive: _vm.operator === 'AND' },on:{"click":function($event){return _vm.editGroupOperator('OR')}}},[_vm._v(" OR ")]),_c('span',{staticClass:"divider none-clickable"},[_vm._v(" / ")]),_c('span',{class:{ inactive: _vm.operator === 'OR' },on:{"click":function($event){return _vm.editGroupOperator('AND')}}},[_vm._v(" AND ")])]):_vm._e(),_c('div',{staticClass:"add dropup",class:{
        small: Object.keys(_vm.group.conditions).length === 1
      },staticStyle:{"width":"36px"},style:({
        'background': _vm.operator === 'OR' ? '#00A65A' : '#695DE1',
        'border-radius': Object.keys(_vm.group.conditions).length > 1 ? '0 0 3px 3px' : '3px'
      })},[_vm._m(0),_c('div',{staticClass:"dropdown-menu sm"},[_c('button',{staticClass:"dropdown-item",on:{"click":_vm.addCondition}},[_vm._v(" Condition ")]),(_vm.level !== 4)?_c('button',{staticClass:"dropdown-item",on:{"click":_vm.addGroup}},[_vm._v(" Group ")]):_vm._e()])])]),_c('div',{staticClass:"condition-group-wrapper d-flex flex-column justify-content-between",style:({'width': _vm.demoRule ? 'auto' : '100%'})},[_vm._l((_vm.group.conditions),function(condition,key){return [(_vm.conditionTypeCondition(condition))?_c('ConditionItem',{key:key,attrs:{"rule":_vm.rule,"ruleId":_vm.ruleId,"conditionGroup":_vm.group,"groupId":_vm.groupId,"condition":condition,"demo-rule":_vm.demoRule,"level":_vm.level},on:{"switch-dropdown":function($event){_vm.switcherDropdown = !_vm.switcherDropdown}}}):_vm._e(),(_vm.conditionTypeConditionComplex(condition))?_c('ConditionItemComplex',{key:key,attrs:{"rule":_vm.rule,"ruleId":_vm.ruleId,"conditionGroup":_vm.group,"groupId":_vm.groupId,"condition":condition,"demo-rule":_vm.demoRule,"level":_vm.level}}):_vm._e(),(_vm.conditionTypeGroup(condition))?_c('ConditionGroup',{key:key,attrs:{"rule":_vm.rule,"ruleId":_vm.ruleId,"group":condition,"level":condition.level,"demo-rule":_vm.demoRule,"operator":condition.operator}}):_vm._e()]})],2)]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"w-100 justify-content-center btn_svg",attrs:{"type":"button","aria-expanded":"false","data-bs-toggle":"dropdown"}},[_c('img',{attrs:{"src":require("../../../../assets/icons/condition-item-plus-icon.svg"),"alt":"icn-add"}})])}]

export { render, staticRenderFns }