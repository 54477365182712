



































































































import Vue from 'vue'
import { mixin as clickaway } from 'vue-clickaway'
export default Vue.extend({
  name: 'baseTimePicker',
  mixins: [clickaway],
  props: {
    show: { required: true, type: Boolean },
    width: { required: true, type: String },
    height: { required: false, type: String },
    minutesProps: { required: false, type: String, default: null },
    hoursProps: { required: false, type: String, default: null },
    selectedTimeRangeProps: { required: false, type: String, default: null },
    minuteStep: { required: false, default: 1, type: Number }
  },

  data () {
    return {
      minutes: 0,
      hours: 1,
      selectedTimeRange: 'AM'
    }
  },

  watch: {
    minutes (minutes) {
      if (typeof minutes === 'string') {
        this.minutes = Number(minutes)
      }

      if (this.minutes > 59) this.minutes = 59
      if (this.minutes < 0) this.minutes = 0
      this.$emit('changeTime', this.result)
    },

    hours (hours) {
      if (typeof hours === 'string') {
        this.hours = Number(hours)
      }

      if (this.hours > 12) this.hours = 12
      if (this.hours < 1) this.hours = 1
      this.$emit('changeTime', this.result)
    },
    selectedTimeRange () {
      this.$emit('changeTime', this.result)
    }
  },

  methods: {
    away () {
      this.$emit('close')
    },
    decrement (time: string) {
      if (time === 'minutes') this.minutes -= this.minuteStep
      if (time === 'hours') this.hours -= 1
    },
    increment (time: string) {
      if (time === 'minutes') this.minutes += this.minuteStep
      if (time === 'hours') this.hours += 1
    }
  },
  computed: {
    result () {
      const format = () => {
        if (this.selectedTimeRange === 'AM') {
          return this.$moment(
            `${this.hours} ${this.minutes}, AM`,
            'hh mm A'
          ).format('HH:mm')
        } else {
          return this.$moment(
            `${this.hours} ${this.minutes}, PM`,
            'hh mm A'
          ).format('HH:mm')
        }
      }

      const dateRange = {
        hours: this.hours,
        minutes: this.minutes,
        selectedTimeRange: this.selectedTimeRange,
        format24: format()
      }
      return dateRange
    }
  },

  mounted () {
    if (this.minutesProps && this.hoursProps) {
      this.hours = this.hoursProps
      this.minutes = this.minutesProps
    }
    if (this.selectedTimeRangeProps) {
      this.selectedTimeRange = this.selectedTimeRangeProps
    }
  }
})
