











































































































import Vue from 'vue'

import BaseTimePicker from '@/components/BaseComponents/BaseTimePicker/BaseTimePicker.vue'
import { mapGetters, mapMutations } from 'vuex'
import 'vue2-datepicker/index.css'
import { TIMEZONES } from '@/constants/Timezones'
import { DAYS_OF_THE_WEEK } from '@/constants/DaysOfTheWeek'
import _ from 'lodash'

export default Vue.extend({
  name: 'TimeGreaterLessThan',
  props: ['rule', 'ruleId', 'condition', 'conditionGroup', 'groupId', 'level', 'demoRule'],
  components: {
    BaseTimePicker
  },
  data () {
    return {
      search_zone: null,
      itemId: this.condition.uid,
      selected_timezone: null,
      selected_days: [],
      value: {},
      selected_time: null,
      showTimePickerStartDate: false
    }
  },
  mounted () {
    this.value = (typeof this.condition.value === 'object') ? this.condition.value : {}
    this.selected_time = this.condition.value?.time
    if (this.condition.value?.timezone) {
      this.selected_timezone = this.condition.value.timezone
    } else {
      const ad_account = this.adAccountById(this.rule.ad_account_id)
      if (ad_account) {
        this.selected_timezone = this.adAccountTimeZone.id
      }
    }
    this.selected_days = this.condition.value?.days || []
  },
  watch: {
    value (v) {
      this.saveConditionProp('value', v)
    },
    selected_time (v) {
      this.value.time = v
    },
    selected_timezone (v) {
      this.value.timezone = v
    },
    selected_days (v) {
      this.value.days = v
    }
  },
  computed: {
    ...mapGetters({
      getConditionItemError: 'rules/getConditionItemError'
    }),
    ...mapGetters('adService', ['getService']),
    ...mapGetters({
      adAccountById: 'adAccounts/getById'
    }),
    filterTimezoneList () {
      return this.filterTimezone.map(v => {
        v.label = v.name
        return v
      })
    },
    filterTimezone () {
      if (this.search_zone) {
        return (
          _.filter(
            this.timezones_list,
            trigger =>
              trigger.name
                .toLowerCase()
                .indexOf(this.search_zone.toLowerCase()) !== -1
          )
        )
      } else {
        return this.timezones_list
      }
    },
    adAccountTimeZone () {
      return {
        name: 'Ad Account',
        title: 'Timezone',
        id: 'AD_ACCOUNT_TIMEZONE'
      }
    },
    timezones_list () {
      return [this.adAccountTimeZone].concat(TIMEZONES)
    },
    timezone_title () {
      if (!this.selected_timezone) return null
      const t = this.timezones_list.find(t => t.id === this.selected_timezone)
      if (!t) return null
      return t.name + ' ' + t.title
    },
    days_list () {
      return DAYS_OF_THE_WEEK
    },
    days_title () {
      if (!this.selected_days || !this.selected_days.length) {
        return 'Select days'
      }
      return this.selected_days.map(d => this.days_list.find(dl => d === dl.id)['short']).join(',')
    }
  },

  methods: {
    ...mapMutations({ removeErr: 'rules/removeErr' }),
    deleteCondition () {
      this.$store.commit('rules/deleteCondition', {
        ruleId: this.rule.id,
        groupId: this.groupId,
        itemId: this.itemId,
        level: this.level
      })
      if (!Object.keys(this.rule.conditions.conditions).length) {
        this.$store.commit('rules/addDefaultGroup', {
          ruleId: this.rule.id,
          level: 0
        })
      }
    },
    getTime (time, key) {
      if (time !== null) {
        const hours = this.$moment(time, ['HH:mm:ss']).format('hh')
        const minutes = this.$moment(time, ['HH:mm:ss']).format('mm')
        const format = this.$moment(time, ['HH:mm:ss']).format('a')
        if (key === 'hours') return hours
        if (key === 'minutes') return minutes
        if (key === 'format') return format.toUpperCase()
      }
    },
    changeTime (key, time) {
      if (time.format24 !== null) {
        this.selected_time = time.format24
      }
    },
    saveConditionProp (type, value) {
      this.$store.dispatch('rules/setConditionProp', {
        ruleId: this.ruleId,
        itemId: this.itemId,
        type: type,
        value: value
      })
    },
    setTimeZone (acc) {
      this.selected_timezone = acc.id
    },
    set_time_zone (zone) {
      this.selected_timezone = zone.id
    }
  }
})
